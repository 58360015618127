import React, { useState, useRef, useEffect } from "react";
import { Markmap } from "markmap-view";
import { loadCSS, loadJS } from "markmap-common";
import { transformer } from "./MarkmapTransformer";

function MarkmapView({ value }) {
  //const [value, setValue] = useState(initValue);
  // Ref for SVG element
  const refSvg = useRef(null);
  // Ref for markmap object
  const refMm = useRef(null);
  // Ref for toolbar wrapper
  const refToolbar = useRef(null);

  useEffect(() => {
    // Create markmap and save to refMm
    if (refMm.current) return;
    const mm = Markmap.create(refSvg.current);
    console.log("create", refSvg.current);
    refMm.current = mm;
    // renderToolbar(refMm.current, refToolbar.current);
  }, []);

  useEffect(() => {
    // Update data for markmap once value is changed
    const mm = refMm.current;
    if (!mm) return;
    const { root } = transformer.transform(value);
    mm.setData(root);
    mm.fit();
  }, [value]);

  // check to see if incoming param "value" is string
  // if not, convert it to string
  if (typeof value !== "string") {
    value = JSON.stringify(value, null, 2);
  }

  return (
    <React.Fragment>
      {/* <div className="flex-1">
        <textarea
          className="w-full h-full border border-gray-400"
          value={value}
          onChange={handleChange}
        />
      </div> */}
      <svg className="flex-1" ref={refSvg} />
      <div className="absolute bottom-1 right-1" ref={refToolbar}></div>
    </React.Fragment>
  );
}

export default MarkmapView;
