import React, { useState, useEffect } from "react";
import Web3 from "web3";
import Modal from "../Modal";
// import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
// import { Loader2, AlertCircle } from "lucide-react";

import { Card, CardContent, CardHeader, CardTitle } from "../ui/Cards";
import Spinner from "../Spinner";

// chains from https://github.com/ethereum-lists/chains/tree/master?tab=readme-ov-file
// tokenlists from  https://github.com/viaprotocol/tokenlists/tree/main/all_tokens

// Minimal ERC20 ABI for balance checking
const ERC20_ABI = [
  {
    constant: true,
    inputs: [{ name: "_owner", type: "address" }],
    name: "balanceOf",
    outputs: [{ name: "balance", type: "uint256" }],
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "decimals",
    outputs: [{ name: "", type: "uint8" }],
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "symbol",
    outputs: [{ name: "", type: "string" }],
    type: "function",
  },
];

// const ChainTokenBalances = ({
//   walletAddress,
//   chain,
//   tokens,
//   //   chains = [
//   //     {
//   //       name: "Ethereum",
//   //       rpc: "https://...",
//   //       tokens: [
//   //         {
//   //           symbol: "FSW",
//   //           name: "Falconswap",
//   //           address: "0xfffffffFf15AbF397dA76f1dcc1A1604F45126DB",
//   //           decimals: 18,
//   //           chainId: 1,
//   //           logoURI:
//   //             "https://tokens.1inch.io/0xfffffffff15abf397da76f1dcc1a1604f45126db.png",
//   //           coingeckoId: "fsw-token",
//   //         },
//   //       ],
//   //     },
//   //  ],
// }) => {
//   const [balances, setBalances] = useState({});
//   const [loading, setLoading] = useState(true);
//   const [errors, setErrors] = useState({});

//   useEffect(() => {
//     const fetchBalances = async () => {
//       setLoading(true);
//       const newBalances = {};
//       const newErrors = {};

//       for (const chain of chains) {
//         try {
//           const web3 = new Web3(chain.rpc);
//           newBalances[chain.name] = { tokens: {} };

//           // Fetch native token balance
//           const nativeBalance = await web3.eth.getBalance(walletAddress);
//           newBalances[chain.name].nativeBalance = web3.utils.fromWei(
//             nativeBalance,
//             "ether"
//           );

//           // Fetch token balances
//           await Promise.all(
//             chain.tokens.map(async (token) => {
//               try {
//                 const contract = new web3.eth.Contract(
//                   ERC20_ABI,
//                   token.address
//                 );

//                 const balance = await contract.methods
//                   .balanceOf(walletAddress)
//                   .call();
//                 const formattedBalance = web3.utils.fromWei(
//                   balance,
//                   "ether" // Adjust if token doesn't use 18 decimals
//                 );

//                 newBalances[chain.name].tokens[token.symbol] = {
//                   ...token,
//                   balance: balance,
//                   formattedBalance: parseFloat(formattedBalance).toFixed(4),
//                 };
//               } catch (err) {
//                 console.error(`Error fetching ${token.symbol} balance:`, err);
//                 newBalances[chain.name].tokens[token.symbol] = {
//                   ...token,
//                   error: `Failed to fetch ${token.symbol} balance`,
//                 };
//               }
//             })
//           );
//         } catch (err) {
//           console.error(`Error with chain ${chain.name}:`, err);
//           newErrors[chain.name] = `Failed to connect to ${chain.name}`;
//         }
//       }

//       setBalances(newBalances);
//       setErrors(newErrors);
//       setLoading(false);
//     };

//     if (walletAddress && chains.length > 0) {
//       fetchBalances();
//     }
//   }, [walletAddress, chains]);

//   const formatTokenBalance = (balance, decimals) => {
//     if (!balance) return "0.0000";
//     const divisor = new Web3().utils
//       .toBN(10)
//       .pow(new Web3().utils.toBN(decimals));
//     const balanceBN = new Web3().utils.toBN(balance);
//     const wholePart = balanceBN.div(divisor);
//     const fractionalPart = balanceBN.mod(divisor);
//     return parseFloat(
//       wholePart.toString() +
//         "." +
//         fractionalPart.toString().padStart(decimals, "0")
//     ).toFixed(4);
//   };

//   if (loading) {
//     return (
//       <div className="flex items-center justify-center p-8">
//         <Spinner className="h-8 w-8 animate-spin text-blue-500" />
//       </div>
//     );
//   }

//   return (
//     <div className="space-y-6">
//       <h2 className="text-2xl font-bold">Chain Balances</h2>

//       <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
//         {chains.map((chain) => (
//           <Card key={chain.name} className="overflow-hidden">
//             <CardHeader className="bg-gray-50">
//               <CardTitle className="flex items-center justify-between">
//                 <span>{chain.name}</span>
//                 {errors[chain.name] && (
//                   <div className="h-5 w-5 text-red-500">Alert</div>
//                 )}
//               </CardTitle>
//             </CardHeader>
//             <CardContent className="p-4">
//               {errors[chain.name] ? (
//                 <div className="text-red-500 text-sm">{errors[chain.name]}</div>
//               ) : (
//                 <div className="space-y-3">
//                   {/* Native Token Balance */}
//                   <div className="flex items-center justify-between p-2 bg-gray-50 rounded">
//                     <span className="font-medium">Native Token</span>
//                     <span>
//                       {parseFloat(
//                         balances[chain.name]?.nativeBalance || 0
//                       ).toFixed(4)}
//                     </span>
//                   </div>

//                   {/* Token Balances */}
//                   <div className="space-y-2">
//                     {chain.tokens.map((token) => {
//                       const tokenData =
//                         balances[chain.name]?.tokens[token.symbol];

//                       return (
//                         <div
//                           key={token.address}
//                           className="flex items-center justify-between p-2 hover:bg-gray-50 rounded"
//                         >
//                           <div className="flex items-center space-x-2">
//                             {token.logoURI && (
//                               <img
//                                 src={token.logoURI}
//                                 alt={token.symbol}
//                                 className="w-6 h-6 rounded-full"
//                                 onError={(e) => {
//                                   e.target.style.display = "none";
//                                 }}
//                               />
//                             )}
//                             <span className="font-medium">{token.symbol}</span>
//                           </div>

//                           <span className="text-sm">
//                             {tokenData?.error ? (
//                               <span className="text-red-500">
//                                 {tokenData.error}
//                               </span>
//                             ) : tokenData?.balance ? (
//                               formatTokenBalance(
//                                 tokenData.balance,
//                                 token.decimals
//                               )
//                             ) : (
//                               "0.0000"
//                             )}
//                           </span>
//                         </div>
//                       );
//                     })}
//                   </div>
//                 </div>
//               )}
//             </CardContent>
//           </Card>
//         ))}
//       </div>
//     </div>
//   );
// };

const ChainTokenBalances = ({ walletAddress, chain, tokens }) => {
  const [balances, setBalances] = useState({});
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const fetchBalances = async () => {
      setLoading(true);
      const newBalances = {};
      const newErrors = {};

      if (chain && tokens && tokens.length > 0) {
        try {
          //const web3 = new Web3(chain.rpc);
          const web3 = new Web3(new Web3.providers.HttpProvider(chain.rpc[0]));
          newBalances[chain.name] = { tokens: {} };

          // Fetch native token balance
          const nativeBalance = await web3.eth.getBalance(walletAddress);
          newBalances[chain.name].nativeBalance = web3.utils.fromWei(
            nativeBalance,
            "ether"
          );
          console.log("native balance", newBalances[chain.name].nativeBalance);

          // Fetch token balances
          await Promise.all(
            tokens.map(async (token) => {
              if (
                token.logoURI == null ||
                token.logoURI === "" ||
                token.logoURI === undefined
              ) {
                // don't fetch token balance if logoURI is not provided
                return;
              }
              try {
                const contract = new web3.eth.Contract(
                  ERC20_ABI,
                  token.address
                );

                const balance = await contract.methods
                  .balanceOf(walletAddress)
                  .call();
                const formattedBalance = web3.utils.fromWei(
                  balance,
                  "ether" // Adjust if token doesn't use 18 decimals
                );

                newBalances[chain.name].tokens[token.symbol] = {
                  ...token,
                  balance: balance,
                  formattedBalance: parseFloat(formattedBalance).toFixed(4),
                };
              } catch (err) {
                console.error(`Error fetching ${token.symbol} balance:`, err);
                newBalances[chain.name].tokens[token.symbol] = {
                  ...token,
                  error: `Failed to fetch ${token.symbol} balance`,
                };
              }
            })
          );
        } catch (err) {
          console.error(`Error with chain ${chain.name}:`, err);
          newErrors[chain.name] = `Failed to connect to ${chain.name}`;
        }
      }

      setBalances(newBalances);
      setErrors(newErrors);
      setLoading(false);
    };

    if (walletAddress && tokens.length > 0) {
      fetchBalances();
    }
  }, [walletAddress, chain, tokens]);

  const formatTokenBalance = (balance, decimals) => {
    if (!balance) return "0.0000";
    // const divisor = new Web3().utils
    //   .toBN(10)
    //   .pow(new Web3().utils.toBN(decimals));
    // const balanceBN = new Web3().utils.toBN(balance);
    // const wholePart = balanceBN.div(divisor);
    // const fractionalPart = balanceBN.mod(divisor);
    // return parseFloat(
    //     wholePart.toString() +
    //       "." +
    //       fractionalPart.toString().padStart(decimals, "0")
    //   ).toFixed(4);

    return new Web3().utils.fromWei(balance.toString(), "ether");
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center p-8">
        <Spinner className="h-8 w-8 animate-spin text-blue-500" />
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold">{chain.name} Chain Balances</h2>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <Card key={chain.name} className="overflow-hidden">
          {errors[chain.name] && (
            <CardHeader className="bg-gray-50">
              <CardTitle className="flex items-center justify-between">
                {/* <span>{chain.name}</span> */}

                <></>
              </CardTitle>
              <div className="h-5 w-5 text-red-500">Alert</div>
            </CardHeader>
          )}
          <CardContent className="p-4">
            {errors[chain.name] ? (
              <div className="text-red-500 text-sm">{errors[chain.name]}</div>
            ) : (
              <div className="space-y-3">
                {/* Native Token Balance */}
                <div className="flex items-center justify-between p-2 bg-gray-50 rounded">
                  <strong>{chain.nativeCurrency.symbol}</strong>&nbsp;&nbsp;
                  <span>
                    {parseFloat(
                      balances[chain.name]?.nativeBalance || 0
                    ).toFixed(4)}
                  </span>{" "}
                </div>

                {/* Token Balances */}
                <div className="space-y-2">
                  {tokens.map((token) => {
                    const tokenData =
                      balances[chain.name]?.tokens[token.symbol];

                    return (
                      <div key={token.address} style={{ display: "flex" }}>
                        {token.logoURI && (
                          <>
                            <img
                              style={{
                                width: "32px",
                                height: "32px",
                                borderRadius: "50%",
                              }}
                              src={token.logoURI}
                              alt={token.symbol}
                              className="w-6 h-6 rounded-full"
                              onError={(e) => {
                                e.target.style.display = "none";
                              }}
                            />
                            &nbsp;
                            <div
                              style={{
                                alignItems: "center",
                                justifyContent: "center",
                                display: "inline-flex",
                              }}
                            >
                              &nbsp;
                              <span style={{ float: "left" }}>
                                {tokenData?.error ? (
                                  <span className="text-red-500">
                                    {tokenData.error}
                                  </span>
                                ) : (
                                  <>
                                    {tokenData?.balance
                                      ? formatTokenBalance(
                                          tokenData.balance,
                                          token.decimals
                                        )
                                      : "0"}{" "}
                                    &nbsp;<strong>{token.symbol}</strong>
                                  </>
                                )}
                              </span>
                            </div>
                          </>
                        )}

                        {/* <span className="text-sm">
                          {tokenData?.error ? (
                            <span className="text-red-500">
                              {tokenData.error}
                            </span>
                          ) : tokenData?.balance ? (
                            formatTokenBalance(
                              tokenData.balance,
                              token.decimals
                            )
                          ) : (
                            "0.0000"
                          )}
                        </span> */}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

const ViewTokenBalances = ({ walletAddress }) => {
  const [showModal, setShowModal] = useState(false);
  const [chains, setChains] = useState([]);
  const [currentChain, setCurrentChain] = useState(null);
  const [tokens, setTokens] = useState([]);
  // fetch /public/chains/chain.json for supported chains
  useEffect(() => {
    const fetchChains = async () => {
      try {
        console.log("fetching chains");
        const response = await fetch("/chains/chains.json");
        const data = await response.json();
        setChains(data);
        //setCurrentChain(data[0]);
        console.log("fetching chains", data);
      } catch (error) {
        console.error("Error fetching chains:", error);
      }
    };

    fetchChains();
  }, [walletAddress]);

  useEffect(() => {
    const fetchTokens = async () => {
      if (currentChain) {
        try {
          const response = await fetch(currentChain.tokens);
          const data = await response.json();
          console.log("fetched tokens for ", currentChain.name, data);
          setTokens(data);
          setShowModal(true);
        } catch (error) {
          console.error("Error fetching tokens:", error);
        }
      }
    };
    fetchTokens();
  }, [currentChain]);

  //   if (currentChain == null) return <Spinner />;
  return (
    <div>
      <select
        value={currentChain?.name || ""}
        onChange={(e) => {
          const selectedChain = chains.find(
            (chain) => chain.name === e.target.value
          );
          setTokens([]); // reset tokens
          setCurrentChain(selectedChain);
        }}
        className="p-2 border rounded"
      >
        <option value="" disabled>
          Select a chain
        </option>
        {chains
          .filter((chain) => chain.tokens)
          .map((chain) => (
            <option key={chain.name} value={chain.name}>
              {chain.name}
            </option>
          ))}
      </select>
      <Modal
        isOpen={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        showClose
      >
        <ChainTokenBalances
          walletAddress={walletAddress}
          chain={currentChain}
          tokens={tokens}
        />
      </Modal>
    </div>
  );
};

export default ViewTokenBalances;
