import React, { useEffect, useState } from "react";
import Modal from "./components/Modal";
import ProgressBar from "./components/ProgressBar";
import { toast } from "react-toastify";

export default function FolderUploader({
  connection,
  onUpload,
  onUploadComplete,
  onFolderUploadFinished,
  user,
}) {
  //const [isUploading, setIsUploading] = useState(false);
  const [allFilesCount, setAllFilesCount] = useState(0);
  const [uploadedFilesCount, setUploadedFilesCount] = useState(0);
  const [currentFilenameUploading, setCurrentFilenameUploading] = useState("");
  const [currentFile, setCurrentFile] = useState(null);

  const [progress, setProgress] = useState(0);
  const fileInputRef = React.useRef(null);

  const readAndUploadChunk = (blob, fileName, chunkIndex, totalChunks) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = async (event) => {
        const base64String = event.target.result.replace(
          /^data:.*\/.*;base64,/,
          ""
        );

        await connection.invoke(
          "UploadFile",
          fileName, // contains relative path
          base64String,
          chunkIndex + 1 === totalChunks // isLastChunk
        );

        const progress = 100 - ((chunkIndex + 1) / totalChunks) * 100;
        setProgress(progress);
        resolve();
      };

      reader.onerror = (error) => {
        console.error("File reading error", error);
        setProgress(0);
        reject(error);
      };

      reader.readAsDataURL(blob); // Read the blob as a Data URL to get base64
    });
  };

  const uploadFile = async (file) => {
    setProgress(0.1);
    const chunkSize = 40960; // Define a suitable chunk size for the file
    const totalChunks = Math.ceil(file.size / chunkSize);
    setCurrentFilenameUploading(file.name);
    console.log("Uploading ", file.name, file.webkitRelativePath);

    for (let i = 0; i < totalChunks; i++) {
      const blob = file.slice(i * chunkSize, (i + 1) * chunkSize);
      await readAndUploadChunk(blob, file.webkitRelativePath, i, totalChunks);
    }

    setProgress(0);
  };

  const uploadFolder = async (folder) => {
    const files = folder.files;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      if (file.isDirectory) {
        await uploadFolder(file);
      } else {
        await uploadFile(file, folder.fullPath + "/");
      }
    }
  };

  const handleFileChange = async (event) => {
    const files = event.target.files;
    let totalSize = 0;
    for (let i = 0; i < files.length; i++) {
      totalSize += await calculateTotalSize(files[i]);
    }
    // TODO check if connected user has enough space in bytes
    console.log(
      `Total size of all files: ${totalSize} bytes in ${files.length} files`
    );
    // check to see if user has enough free space
    if (user.storage < totalSize) {
      toast.error(
        <>
          <b>Reserve more space</b>
          <br />
          You don't have enough space.
        </>
      );
      return;
    }

    setAllFilesCount(files.length);
    setUploadedFilesCount(0);

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      setCurrentFile(file);
      if (file.isDirectory) {
        await uploadFolder(file);
      } else {
        await uploadFile(file);
      }
      setUploadedFilesCount((prev) => prev + 1);
    }
    console.log("All files uploaded", user);
    if (onFolderUploadFinished) onFolderUploadFinished(user.address);
  };

  const calculateTotalSize = async (item) => {
    let totalSize = 0;
    if (item.isDirectory) {
      const directoryReader = item.createReader();
      const entries = await new Promise((resolve) =>
        directoryReader.readEntries(resolve)
      );
      for (let i = 0; i < entries.length; i++) {
        totalSize += await calculateTotalSize(entries[i]);
      }
    } else {
      totalSize = item.size;
    }
    return totalSize;
  };

  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    //console.log("change", currentFilenameUploading, uploadedFilesCount);
  }, [currentFilenameUploading, uploadedFilesCount]);

  return (
    <>
      <input
        type="file"
        onChange={handleFileChange}
        style={{ display: "none" }} // Hide the file input
        ref={fileInputRef} // Reference the input for triggering click
        webkitdirectory="true"
      />
      <Modal isOpen={progress > 0}>
        <h3>Uploading</h3>
        Please wait <br />
        {currentFilenameUploading} <br />
        <ProgressBar progress={progress} />
        <br />
        {uploadedFilesCount}/{allFilesCount} files uploaded
        <br />
        Popup will close automatically
      </Modal>
      {progress > 0 ? (
        <></>
      ) : (
        <button onClick={handleUploadButtonClick}>📽 Folder Upload</button>
      )}
    </>
  );
}
