import React from "react";
import { useState } from "react";
import ContactUs from "./parts/contact_us";
import Terms from "./parts/terms";
import Privacy from "./parts/privacy";
import Social from "./parts/social";
import About from "./parts/about";
import DataForGood from "./parts/data_for_good";
import Partnerships from "./parts/partnerships";
import Modal from "./components/Modal";

export default function Footer({ children }) {
  const [isHovered, setIsHovered] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [compToShow, setCompToShow] = useState(null);

  const onShowModal = (comp) => {
    setCompToShow(comp);
  };

  return (
    <>
      <footer
        className="footer"
        onMouseEnter={() => setShowMore(true)}
        onMouseLeave={() => setShowMore(false)}
      >
        <div className="container">
          {showMore && (
            <small>
              <span
                className="link"
                onClick={() => onShowModal(<DataForGood />)}
              >
                Data for Good{" "}
              </span>
              <span
                className="link"
                onClick={() => onShowModal(<Partnerships />)}
              >
                Partnerships{" "}
              </span>
              {/* <span className="link">Workshops </span> */}
              {/* <span className="link">Webinars </span> */}
              <span className="link" onClick={() => onShowModal(<Terms />)}>
                Terms{" "}
              </span>
              <span className="link" onClick={() => onShowModal(<Privacy />)}>
                Privacy{" "}
              </span>
              <span className="link" onClick={() => onShowModal(<Social />)}>
                Social{" "}
              </span>
              <span className="link" onClick={() => onShowModal(<About />)}>
                About{" "}
              </span>
              <span className="link" onClick={() => onShowModal(<ContactUs />)}>
                Contact{" "}
              </span>
              <br />
            </small>
          )}

          {isHovered && (
            <>
              <Modal isOpen={isHovered} onClose={() => setIsHovered(false)}>
                {children}
              </Modal>
            </>
          )}
          <small
            className="link"
            onClick={() => setIsHovered(true)}
            // onMouseLeave={() => setIsHovered(false)}
          >
            Datafund
          </small>
        </div>
      </footer>

      <Modal
        isOpen={compToShow != null}
        onClose={() => onShowModal(null)}
        showClose={false}
      >
        {compToShow}
      </Modal>
    </>
  );
}
