import React, { useState } from "react";
import Modal from "./components/Modal";
import ProgressBar from "./components/ProgressBar";
import { toast } from "react-toastify";

export default function FileUploader({
  connection,
  onUpload,
  onUploadComplete,
}) {
  //const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const fileInputRef = React.useRef(null);

  const uploadFile = async (file) => {
    setProgress(0.1);
    const chunkSize = 14096; // Define a suitable chunk size for the file
    const totalChunks = Math.ceil(file.size / chunkSize);

    for (let i = 0; i < totalChunks; i++) {
      const blob = file.slice(i * chunkSize, (i + 1) * chunkSize);
      const reader = new FileReader();

      reader.onload = async (event) => {
        const base64String = event.target.result.replace(
          /^data:.*\/.*;base64,/,
          ""
        );

        await connection.invoke(
          "UploadFile",
          file.name,
          base64String,
          i + 1 === totalChunks // isLastChunk
        );

        const progress = 100 - ((i + 1) / totalChunks) * 100;
        setProgress(progress);

        // console.log(
        //   "Chunk sent",
        //   i,
        //   totalChunks,
        //   chunkSize,
        //   file.size,
        //   progress
        // );
      };

      reader.onerror = (error) => {
        console.error("File reading error", error);
        setProgress(0);
      };

      reader.readAsDataURL(blob); // Read the blob as a Data URL to get base64
    }

    setProgress(0);
  };
  // Handle file input change
  const handleFileChange = async (event) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      if (!connection) {
        console.log("Connection not found");
      }
      // Assuming you have a method 'UploadFile' in your SignalR hub
      try {
        if (onUpload) onUpload(file);

        await uploadFile(file);
        // display file info (size, name, type, etc.)
        console.log("File upload initiated", file);
        //toast("File upload initiated ...");
      } catch (error) {
        console.error("File upload failed", error);
      }
    }
  };
  const handleUploadButtonClick = () => {
    // Trigger the hidden file input's click event
    fileInputRef.current.click();
  };

  return (
    <>
      <input
        type="file"
        onChange={handleFileChange}
        style={{ display: "none" }} // Hide the file input
        ref={fileInputRef} // Reference the input for triggering click
      />
      <Modal isOpen={progress > 0}>
        <h3>Uploading</h3>
        Please wait
        <ProgressBar progress={progress} />
        Popup will close automatically
      </Modal>
      {progress > 0 ? (
        <></>
      ) : (
        <button onClick={handleUploadButtonClick}>📁 File Upload</button>
      )}
    </>
  );
}
