import React from "react";
import staticNodeActions from "./ActionMarketplace.static.js";
import ActionTaskCard from "./ui/ActionTaskCard.js";

/*
// node action
{
    "name": "BudgetVarianceReporting",
    "description": "Analyze and report on budget variances, identifying differences between actual and budgeted figures and their causes.",
    "providerNodeId": "33a61761aa154073bf81474d269399b0",
    "resource": "",
    "data": "",
    "owner": "",
    "connectionId": "",
    "fileTypes": ".txt, .md, .csv, .json",
    "s": "",
    "sm": "",
    "project": "",
    "m": null,
    "time": 1729769817,
    "d": 0,
    "g": "BusinessReporting",
    "sc": 4,
    "r": 0,
    "wh": ""
}
*/

const ActionMarketplace = ({ nodeActions }) => {
  const randomActions = [];
  for (let i = 0; i < 5; i++) {
    const randomIndex = Math.floor(Math.random() * staticNodeActions.length);
    randomActions.push(staticNodeActions[randomIndex]);
  }
  // get all distinct groups (.g) from staticNodeActions
  const groups = staticNodeActions.reduce((acc, action) => {
    if (!acc.includes(action.g)) {
      acc.push(action.g);
    }
    return acc;
  }, []);

  return (
    <div style={{ padding: "20px" }}>
      {/* Existing header and main content... */}

      <section style={{ marginTop: "20px" }}>
        <h2>Featured Tasks</h2>
        <div className="product-cards">
          {randomActions.map((product, index) => (
            <ActionTaskCard key={index} {...product} />
          ))}
        </div>
        <h4>{staticNodeActions.length} Tasks</h4>
      </section>

      {/* <section style={{ marginTop: "20px" }}>
        <h3>Groups</h3>
        <div className="product-cards">
          {groups.map((group, index) => (
            <GroupCard key={index} name={group} />
          ))}
        </div>
      </section>
      <section style={{ marginTop: "20px" }}>
        <h3> Tasks {staticNodeActions.length}</h3>
      </section> */}
    </div>
  );
};

export default ActionMarketplace;
