import React, { useState } from "react";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";

export default function UserCollection({
  data,
  collectionObj,
  showTitle = false,
}) {
  function getFolderAndFileName(filePath) {
    try {
      return filePath.split("/").slice(-1).join("");
    } catch (e) {
      console.error("Error getting folder and file name: " + e, filePath);
    }
    return filePath;
  }

  // Usage in your component

  // Effect hook to load data - here we're just setting the static data
  //   useEffect(() => {
  //     // parse string to JSON
  //     try {
  //       //var data = JSON.parse(stringWithJsonData);
  //       // check that data is an array
  //       debugger;
  //       if (!Array.isArray(data)) {
  //         throw new Error("Data is not an array");
  //       }

  //       setFileData(data);
  //       console.log("Data loaded: " + JSON.stringify(data));
  //     } catch (e) {
  //       console.log("Error parsing JSON data: " + e);
  //     }
  //     //setFileData(stringWithJsonData);
  //   }, []);

  if (!data || data.length === 0 || !Array.isArray(data)) {
    return <div>Select collection</div>;
  }
  function copyToClipboard(reference) {
    console.log("copyToClipboard", reference);
    navigator.clipboard.writeText(reference);
    toast(
      <>
        <h2>Copied</h2> <b>{reference}</b>
      </>
    );
  }
  //console.log("data", data);

  return (
    <div>
      {showTitle && <h2>{collectionObj.name} collection</h2>}
      <small>
        {/* {collectionObj.description} - {collectionObj.owner} */}
        Items in collection: {data.length} <br />
      </small>
      <Tooltip anchorSelect="#collection-copy-ref" place="top-start">
        <h3>Copy Reference</h3>Copy reference to clipboard
      </Tooltip>
      Right click and Save-As to download file from Swarm with no charges.
      (limits apply for free tier)
      <ul>
        {data.map((file, index) => (
          <li key={index}>
            <span
              id="collection-copy-ref"
              style={{ cursor: "pointer" }}
              onClick={() => copyToClipboard(file.reference)}
            >
              📋
            </span>
            &nbsp;
            <strong>
              <a
                href={`https://gateway.fairdatasociety.org/bzz/${file.reference}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {getFolderAndFileName(file.file)}
              </a>{" "}
            </strong>{" "}
            {/* <a href={`./?download=${file.hash}`}>
              ({file.size}) - [{getFolderAndFileName(file.file)}]
            </a> */}
            <small style={{ float: "right2" }}>
              {window.formatBytes(file.size)}&nbsp;
              {file.contentType}
            </small>
            {/* <br />
            <strong>Reference:</strong> {file.reference} <br />
            <strong>SHA256:</strong> {file.sha256} <br /> */}
          </li>
        ))}
      </ul>
    </div>
  );
}
