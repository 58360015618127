import React, { useEffect } from "react";
import { toast } from "react-toastify";

// TODO, service will return dynamic object with properties,
//  display them all in tabs, with names captial case

export default function UserFiles({
  address,
  manifests,
  formatBytes,
  selectedUser,
  assetName,
  onReceiveManifest,
  onDeleteManifest,
  showTitle = true,
  isHome,
}) {
  const [filterText, setFilterText] = React.useState("");
  useEffect(() => {}, []);
  // function to copy to clipboard
  const copyToClipboard = (e, resource) => {
    console.log("copyToClipboard", e.target.innerText);
    navigator.clipboard.writeText(
      "https://spaces.datafund.io/?download=" + resource.resourcePath
    );
    toast("Paylink copied to clipboard");
  };

  if (manifests.length === 0) {
    return (
      <div className="react-tabs">
        <span>No files</span>
      </div>
    );
  }

  return (
    <>
      <input
        type="text"
        value={filterText}
        className="filter-search"
        onChange={(e) => setFilterText(e.target.value)}
        placeholder="Filter files..."
      />

      <div className="react-tabs" style={{ width: "90%" }}>
        {manifests.length > 0 && (
          <>
            {showTitle && <h2>Files {selectedUser.name} </h2>}
            <div>
              {manifests
                .filter((manifest) => manifest.filename.includes(filterText))
                .map((manifest, index) => (
                  <div
                    key={index}
                    className="resource-highlite-row"
                    style={{
                      display: "flex",
                    }}
                    // onClick={(e) => onReceiveResource(resource.resourcePath)}
                  >
                    <span
                      onClick={(e) => onReceiveManifest(manifest.resourcePath)}
                      className="resource-item"
                      style={{
                        cursor: "pointer",
                        maxWidth: "80%",
                        display: "inline-block",
                      }}
                    >
                      📂 {manifest.filename}
                    </span>

                    <div
                      style={{
                        float: "right",
                        right: "0.5rem",
                        position: "absolute",
                      }}
                    >
                      <small>
                        <span className="resource-not-important">
                          {manifest.price}
                        </span>{" "}
                        {assetName}
                      </small>
                      &nbsp;&nbsp;
                      <small className="resource-not-important">
                        {formatBytes(manifest.size)}
                      </small>{" "}
                      &nbsp;
                      <span
                        onClick={(e) => copyToClipboard(e, manifest)}
                        className="resource-item resource-not-important"
                      >
                        Paylink
                      </span>
                      &nbsp;&nbsp;
                      {address === manifest.owner && (
                        <span
                          className="resource-item"
                          style={{ cursor: "not-allowed" }}
                        >
                          <small onClick={() => onDeleteManifest(manifest)}>
                            🗑
                          </small>
                        </span>
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </>
        )}
      </div>
    </>
  );
}
