import React, { useState, useEffect } from "react";
import { ReactGrid } from "@silevis/reactgrid";
import "@silevis/reactgrid/styles.css";
import Papa from "papaparse"; // Import PapaParse for CSV parsing

const MyCSVGridComponent = ({ stringData }) => {
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    // Reset state on new data
    setColumns([]);
    setRows([]);
    setError("");

    // Parse the CSV data
    const parseResult = Papa.parse(stringData, {
      header: true,
      skipEmptyLines: true,
    });

    // Check for parsing errors
    if (parseResult.errors.length > 0) {
      console.error("CSV Parsing Errors:", parseResult.errors);
      setError("Invalid tabular format. Please provide a valid CSV file.");
      return;
    }

    const jsonData = parseResult.data;

    // Validate that there is at least one row of data
    if (!jsonData || jsonData.length === 0) {
      setError("CSV data is empty or missing headers.");
      return;
    }

    // Validate that all rows have the same number of columns
    const columnCount = Object.keys(jsonData[0]).length;
    const inconsistentRows = jsonData.some(
      (row) => Object.keys(row).length !== columnCount
    );
    if (inconsistentRows) {
      setError("Inconsistent number of columns in CSV data.");
      return;
    }

    // Prepare columns based on headers
    // const generatedColumns = Object.keys(jsonData[0]).map((header, index) => ({
    //   columnId: `col-${index}`,
    //   width: 150,
    //   title: header,
    // }));
    // Prepare columns based on headers
    const generatedColumns = parseResult.meta.fields.map((header, index) => ({
      columnId: `col-${index}`,
      width: 100,
      title: header,
    }));
    setColumns(generatedColumns);

    // Prepare rows based on parsed CSV data
    const generatedRows = jsonData.map((row, rowIndex) => ({
      rowId: `row-${rowIndex}`,
      cells: Object.values(row).map((cell) => ({
        type: "text",
        text: cell ? String(cell) : "",
      })),
    }));
    setRows(generatedRows);

    // setTimeout(() => {
    //   autoSizeColumns(generatedColumns, generatedRows, setColumns);
    // }, 0);
  }, [stringData]);

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  if (columns.length === 0 || rows.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <ReactGrid
      rows={rows}
      columns={columns}
      enableColumnResize={true}
      enableRowSelection={false}
      // Add other necessary props here
    />
  );
};

export default MyCSVGridComponent;
