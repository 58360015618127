// UserData {
//     "user": {
//         "address": "0xC99C422018c4272a077Dd719eD974A1B3e29BFcD",
//         "info": "",
//         "name": "MHhDOTlDNDIyMDE4YzQyNzJhMDc3RGQ3MTllRDk3NEExQjNlMjlCRmNE",
//         "likes": "MA==",
//         "score": 0,
//         "image": "",
//         "misc": ""
//     },
//     usage: 983721, // bytes
//     "outstandingPayments": {}
// }
import React from "react";
import OutstandingPayments from "./OutstandingPayments";
import obfuscateAddress from "../utils/obfuscateAddress";

function calculateFreeStoragePercentage(consumed, total) {
  if (total === 0) {
    return 0;
  }

  const freeStorage = total - consumed;
  const freeStoragePercentage = (freeStorage / total) * 100;

  return freeStoragePercentage.toFixed(2); // Returns the percentage with 2 decimal places
}

export default function UserData({
  userData,
  user,
  selected,
  onOutstandingPayment,
  formatBytes,
  onClearSelectedUser,
  isHome,
  onGoHome,
}) {
  const [showInfo, setShowInfo] = React.useState(false);

  return (
    <div className="user-data">
      {/* <span onClick={() => setShowInfo(!showInfo)}>{showInfo ? "▼" : "▲"}</span> */}
      <span className="glitch user-top-meu">
        {user?.name.length > 0 ? user?.name : obfuscateAddress(user?.address)}
        &nbsp;&nbsp;
        {/* {selected === false && <>🏢&nbsp;&nbsp;</>} */}
        {isHome && <code onClick={() => onGoHome()}>🏠&nbsp;&nbsp;</code>}
        <b
          style={{ fontSize: "0.3em", verticalAlign: "super" }}
          onClick={() => setShowInfo(!showInfo)}
        >
          {showInfo ? "▼" : "▲"}
        </b>
        {selected && (
          <div
            style={{ float: "left" }}
            className="resource-item"
            onClick={() => onClearSelectedUser()}
          >
            {"✕"}&nbsp;
          </div>
        )}
      </span>

      {showInfo && (
        <small>
          <hr />
          <strong>{user?.score}</strong> Points <br />
          Space: <strong>{formatBytes(user?.storage)} </strong>
          Consumed: <strong>{formatBytes(user?.consumed)} </strong>
          <br />
          Uploaded: <strong>{formatBytes(user?.uploaded)} </strong>
          Downloaded: <strong>{formatBytes(user?.downloaded)} </strong>
          {/* Actual: <strong>{formatBytes(user?.actualStorage)} </strong> */}
          {userData && (
            <>
              Usage: <strong>{formatBytes(userData?.usage)} </strong>
            </>
          )}
          <br />
          {calculateFreeStoragePercentage(
            Math.min(user?.consumed || 0, user?.storage || 0),
            Math.max(user?.consumed || 0, user?.storage || 0)
          )}
          % consumption to storage &nbsp;
          {calculateFreeStoragePercentage(
            Math.min(user?.uploaded || 0, user?.downloaded || 0),
            Math.max(user?.uploaded || 0, user?.downloaded || 0)
          )}
          % uploaded to downloaded
          <br />
          {user?.info.length > 0 && <p>{user?.info}</p>}
          {/* Address: {(userData?.user?.address)} */}
          {user?.likes} <br />
          {/*   <br /> <br />
      Image: {(userData?.user?.image)}
      <br />
      Misc: {(userData?.user?.misc)} <br />*/}
          <OutstandingPayments
            payments={userData?.outstandingPayments}
            onOutstandingPayment={onOutstandingPayment}
          />
          <hr />
        </small>
      )}
    </div>
  );
}
