/* 
 a component to display use contents
 a similar functionaliy like in github browse code (readme, license, contributing, code of conduct, etc.)

 if user updates any of: 
    markdown files uploaded to service
*/

import React, { useEffect } from "react";
import MarkmapView from "./MarkmapView";
import Markdown from "react-markdown";

//import "react-tabs/style/react-tabs.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
// import MarkdownAsync from "./MarkdownAsync";
// import remarkGfm from "remark-gfm";
// import remarkMermaid from "remark-mermaidjs";
// import remarkMath from "remark-math";

// TODO, service will return dynamic object with properties,
//  display them all in tabs, with names captial case

export default function UserContents({
  address,
  selectedUser,
  contents,
  userContent,

  onDeleteContents,
  showTitle = true,
  isHome,

  onGetUserContent,
  onGetDataLineage,
  onGetDataProvenance,
}) {
  const [isMarkdownVisible, setIsMarkdownVisible] = React.useState(true);
  const [isMarkmapVisible, setIsMarkmapVisible] = React.useState(true);
  const [getDataProvenance, setDataProvenance] = React.useState(false);
  const [getDataLineage, setDataLineage] = React.useState(false);
  const [filterText, setFilterText] = React.useState("");

  useEffect(() => {}, []);
  // function to return capital case of a string
  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
  function removeFileType(str) {
    return str.replace(/\.[^/.]+$/, "");
  }

  const tabs = Object.keys(contents)
    .filter((key) =>
      removeFileType(key).toLowerCase().includes(filterText.toLowerCase())
    )
    .map((key) => {
      return (
        <Tab
          key={key}
          onClick={() => onGetUserContent(selectedUser.address, key)}
        >
          {removeFileType(key)}
        </Tab>
      );
    });

  // const tabs = Object.keys(contents).map((key) => {
  //   return <Tab key={key}>{toTitleCase(removeFileType(key))}</Tab>;
  // });

  //console.log("cont", address, selectedUser);
  const tabPanels = Object.keys(contents)
    .filter((key) =>
      removeFileType(key).toLowerCase().includes(filterText.toLocaleLowerCase())
    )
    .map((key) => {
      return (
        <TabPanel key={"--" + key}>
          <span
            className="resource-item"
            style={{ cursor: "pointer" }}
            onClick={() => setIsMarkdownVisible(!isMarkdownVisible)}
          >
            {isMarkdownVisible ? <> [-] Markdown</> : <> [+] Markdown</>}
          </span>
          &nbsp;
          <span
            className="resource-item"
            style={{ cursor: "pointer" }}
            onClick={() => setIsMarkmapVisible(!isMarkmapVisible)}
          >
            {isMarkmapVisible ? <> [-] Markmap</> : <> [+] Markmap</>}
          </span>
          {/* &nbsp;
          <span
            className="resource-item"
            style={{ cursor: "pointer" }}
            onClick={() => onGetDataProvenance()}
          >
            [?] Provenance
          </span>
          &nbsp;
          <span
            className="resource-item"
            style={{ cursor: "pointer" }}
            onClick={() => onGetDataLineage()}
          >
            [?] Lineage
          </span> */}
          {address === selectedUser.address && key !== "address" && (
            <span
              className="resource-item"
              style={{ cursor: "pointer", float: "right" }}
              onClick={() => onDeleteContents(key)}
            >
              🗑 Delete Content
              {/* {key} Content from Space */}
            </span>
          )}
          {/* {isMarkdownVisible && <Markdown>{contents[key]}</Markdown>} */}
          {isMarkdownVisible && <Markdown>{userContent}</Markdown>}
          {/* {console.log("userContent", userContent)} */}
          {isMarkmapVisible && (
            <div
              style={{
                position: "relative",
                width: "95vw",
                height: "75vh",
                backgroundColor: "rgba(50, 50, 50, 0.5)",
                color: "var(--darktextcolor)",
              }}
            >
              <MarkmapView
                //value={contents[key]}
                value={userContent}
                // style={{ width: "10%", height: "500px", color: "white" }}
              />
            </div>
          )}
        </TabPanel>
      );
    });

  return (
    <>
      <input
        type="text"
        value={filterText}
        className="filter-search"
        onChange={(e) => setFilterText(e.target.value)}
        placeholder="Filter contents..."
      />
      <Tabs>
        <TabList>{tabs}</TabList>
        {/* style={{ margin: "1%", padding: "1%" }} */}
        <div>{tabPanels}</div>
      </Tabs>
    </>
  );
}
