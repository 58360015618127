import React from "react";

function ProgressBar({ progress }) {
  //const [progress, setProgress] = useState(0); // Initialize progress state to 0

  return (
    <div>
      {/* Progress Bar */}
      <div style={{ width: "100%", backgroundColor: "#ddd" }}>
        <div
          style={{
            height: "24px",
            width: `${progress}%`,
            backgroundColor: "#7061c5",
            textAlign: "center",
            color: "white",
          }}
        >
          {Math.floor(progress)}%
        </div>
      </div>
    </div>
  );
}

export default ProgressBar;
