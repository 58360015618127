import SpaceUser from "./SpaceUser";
import obfuscateAddress from "../utils/obfuscateAddress";

export default function SpaceUsers({
  spaceUsers,
  address,
  formatBytes,
  onReceiveManifest,
  onGetUserCollection,
  onGetUserContent,
  collection,
  content,
  onClearSpaceUsers,
  setSelectedUser,
  onGetUserContents,
  nodeActions,
}) {
  return (
    <div className="user-data">
      <>
        <br />
        <h2>
          Found {spaceUsers.length} Spaces
          <div
            style={{ float: "left" }}
            className="resource-item"
            onClick={() => onClearSpaceUsers()}
          >
            {"✕"}&nbsp;
          </div>
        </h2>
      </>
      {spaceUsers.map((spaceUser, i) => (
        <div key={spaceUser.user.address + "_" + i}>
          <SpaceUser
            address={address}
            spaceUser={spaceUser}
            formatBytes={formatBytes}
            onReceiveManifest={onReceiveManifest}
            onGetUserCollection={onGetUserCollection}
            onGetUserContent={onGetUserContent}
            collection={collection}
            content={content}
            setSelectedUser={setSelectedUser}
            onGetUserContents={onGetUserContents}
            onClearSpaceUsers={onClearSpaceUsers}
          />
        </div>
      ))}
    </div>
  );
}
