import { Card, CardContent, Badge, CardFooter } from "./Cards.js";

/* 
character =  {
    codename: "Biz Guardian",
    fullName: "Dr. Elena Foster",
    age: 39,
    profession: "Risk & Governance Expert",
    description: "Risk manager & compliance architect",
    background: "Masters both risk management and governance",
    skills: "Ensures sustainable business practices",
    strengths: "Creates robust governance frameworks",
    signature: "Risk management & compliance systems",
  }
*/

const CharacterCard = ({
  codename,
  fullName,
  age,
  profession,
  description,
  background,
  skills,
  strengths,
  signature,
  size,
}) => (
  <Card className="product-card" style={{ tranform: "scale(0.1)" }}>
    {size === undefined && (
      <div className="product-card__image-container">
        <img
          src={`Character.webp`}
          alt={fullName}
          className="product-card__image"
        />
        <h1 className="product-card__publisher-text-glow">{profession}</h1>
      </div>
    )}
    {size === undefined && (
      <Badge variant="secondary" className="product-card__badge">
        {codename}
      </Badge>
    )}
    {size && <h3 className="product-card__title">{profession}</h3>}
    <CardContent className="product-card__content">
      {/* <h3 className="product-card__title">{profession}</h3> */}
      <h3 className="product-card__description">{description}</h3>
      <p className="product-card__description">{background}</p>
      {/* <p className="product-card__description">{skills}</p> */}
      {/* <p className="product-card__description">{strengths}</p>
      <p className="product-card__description">{signature}</p> */}
    </CardContent>
    <CardFooter className="product-card__footer">
      {/* <Badge
        // variant="outline"
        // // className={`product-card__badge--${age > 38 ? "free" : "paid"}`}
        // className="product-card__badge"
        variant="secondary"
        className="product-card__badge"
      >
        {strengths}
      </Badge> */}
      <span className="product-card__visitors">{strengths}</span>
      <span className="product-card__visitors">{signature}</span>
    </CardFooter>
  </Card>
);

export default CharacterCard;
