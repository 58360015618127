import React from "react";
import convertToCapitalSpaceCase from "../../utils/convertToCapitalSpaceCase.js";
import { Card, CardContent, Badge } from "./Cards.js";
import SmartImageCard from "./SmartImageCard.js";

const ActionTaskCard = ({
  image,
  title,
  name,
  g,
  description,
  free,
  visitors,
}) => (
  <Card className="product-card">
    <div className="product-card__image-container">
      {/* <img src={g + ".webp"} alt={title} className="product-card__image" /> */}
      <SmartImageCard g={g} title={title} />
      <h1 className="product-card__publisher-text-glow">
        {convertToCapitalSpaceCase(name)}
      </h1>
    </div>
    {/* <img src={g + ".webp"} alt={title} className="product-card__image" />
      <h1 className="product-card__publisher-text">{name}</h1> */}
    {/* <ProductImageCard g={g} title={title} /> */}
    <Badge variant="secondary" className="product-card__badge">
      {convertToCapitalSpaceCase(g)}
    </Badge>
    <CardContent className="product-card__content">
      {/* <div className="product-card__publisher">
          <div className="product-card__publisher-icon"></div>
          <span className="product-card__publisher-text">
            {convertToCapitalSpaceCase(name)}
          </span>
        </div> */}
      <h3 className="product-card__title">{title}</h3>
      <p className="product-card__description">{description}</p>
    </CardContent>

    {/* <CardFooter className="product-card__footer">
        <Badge
          variant="outline"
          className={`product-card__badge--${free ? "free" : "paid"}`}
        >
          {free ? "FREE" : "PAID"}
        </Badge>
        <span className="product-card__visitors">{visitors} visitors</span>
      </CardFooter> */}
  </Card>
);

export default ActionTaskCard;
