/* 
 a component to display use contents
 a similar functionaliy like in github browse code (readme, license, contributing, code of conduct, etc.)

 if user updates any of: 
    markdown files uploaded to service
*/

import React, { useEffect } from "react";
import Markdown from "react-markdown";
//import "react-tabs/style/react-tabs.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import UserCollection from "./UserCollection";

// TODO, service will return dynamic object with properties,
//  display them all in tabs, with names captial case

export default function UserCollections({
  address,
  collections,
  collection,
  onDeleteCollection,
  user,
  onGetUserCollection,
  showTitle = true,
  isHome,
}) {
  const [filterText, setFilterText] = React.useState("");
  useEffect(() => {}, []);

  // tabs are collection.name
  const tabs = collections
    .filter((collection) =>
      collection.name.toLowerCase().includes(filterText.toLowerCase())
    )
    .map((collection, i) => {
      return (
        <Tab
          key={"cc" + i}
          onClick={() => onGetUserCollection(user.address, collection.name)}
        >
          {collection.name}
        </Tab>
      );
    });
  const tabPanels = collections.map((collectionObj, index) => {
    return (
      <TabPanel key={"--" + collectionObj.name + "-" + index}>
        <>
          {/* if collection is string then display it, else display the collection object */}
          {typeof collection === "string" ? (
            <Markdown>{collection}</Markdown>
          ) : (
            <UserCollection data={collection} collectionObj={collectionObj} />
          )}

          {address === collectionObj.owner && collection !== null && (
            <>
              <hr />
              <span
                className="resource-item"
                style={{ cursor: "not-allowed", float: "right" }}
                onClick={() => onDeleteCollection(collectionObj, true)}
              >
                🗑 Delete Collection
              </span>
            </>
          )}
          {/* // <Markdown>{collection}</Markdown>
        // <UserCollection data={collection} /> */}
        </>
      </TabPanel>
    );
  });

  if (collections.length === 0) {
    return (
      <div className="react-tabs">
        <span>No collections</span>
      </div>
    );
  }

  return (
    <>
      <input
        type="text"
        value={filterText}
        className="filter-search"
        onChange={(e) => setFilterText(e.target.value)}
        placeholder="Filter collections..."
      />
      <Tabs>
        <TabList>{tabs}</TabList>
        <div style={{ margin: "1%", padding: "1%" }}>{tabPanels}</div>
      </Tabs>
    </>
  );
}
