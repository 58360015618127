export default function ContactUs({ onOk }) {
  const agree = async () => {
    console.log("agree");
    if (onOk) onOk(true);
  };
  return (
    <div className="terms">
      <h2>Contact us</h2>
      <p>
        <a href="https://datafund.io">Datafund</a>
      </p>

      {/* <button onClick={() => agree()}>Ok</button> */}
    </div>
  );
}
