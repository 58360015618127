/* 
   displays SpaceUser 
     - contents 
     - collections 
     - files (manifests) 
     */
import { useEffect, useState } from "react";
import UserCollections from "./UserCollections";
import UserContents from "./UserContents";
import UserFiles from "./UserFiles";
import Modal from "./Modal";
import Markdown from "react-markdown";
import UserCollection from "./UserCollection";
import obfuscateAddress from "../utils/obfuscateAddress";

function SpaceContents({
  contents,
  onGetUserContent,
  onDisplayContent,
  showTitle = true,
}) {
  return (
    <>
      {showTitle && <h2>Contents</h2>}
      <ul>
        {contents.map((content, index) => (
          <li
            className="resource-item-dark"
            key={index}
            onClick={() => {
              onGetUserContent(content.owner, content.name);
              onDisplayContent(content);
            }}
          >
            📖 {content.name}
          </li>
        ))}{" "}
      </ul>
    </>
  );
}
function SpaceCollection({
  collections,
  onGetUserCollection,
  onDisplayCollection,
  showTitle = true,
}) {
  return (
    <>
      {showTitle && <h2>Collections</h2>}
      <ul>
        {collections.map((collection, index) => (
          <li
            className="resource-item"
            key={index}
            onClick={() => {
              onGetUserCollection(collection.owner, collection.name);
              onDisplayCollection(collection);
            }}
          >
            🗄 {collection.name}
          </li>
        ))}
      </ul>
    </>
  );
}
function SpaceFiles({
  manifests,
  address,
  selectedUse,
  formatByte,
  onReceiveManifest,
  onDeleteManifest,
  showTitle,
}) {
  return (
    <>
      {showTitle && <h2>Collections</h2>}
      <ul>
        {manifests.map((manifest, index) => (
          <li
            className="resource-item"
            key={index}
            onClick={() => {
              onReceiveManifest(manifest.resourcePath);
            }}
          >
            📂&nbsp;
            {manifest.filename}
          </li>
        ))}
      </ul>
    </>
  );
}

export default function SpaceUser({
  address,
  spaceUser,
  selectedUser,
  formatBytes,
  onReceiveManifest,
  onGetUserContent,
  onGetUserCollection,
  showTitle = false,
  collection,
  content,
  setSelectedUser,
  onGetUserContents,
  onClearSpaceUsers,
}) {
  const [showContent, setShowContent] = useState(null);
  const [showCollection, setShowCollection] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(true);

  useEffect(() => {
    //console.log("showContent change", showContent);
  }, [showContent]);
  // TODO This is not working good, requires two clicks for changes to happen
  const displayContent = (content) => {
    setIsModalOpen(true);
    setShowContent(content);
  };
  const displayCollection = (collectionObj) => {
    setIsModalOpen(true);
    setShowCollection(collectionObj);
  };
  useEffect(() => {
    //console.log("collection", collection);
  }, [collection]);
  useEffect(() => {
    //console.log("content", content);
  }, [content]);

  const dummyStub = () => {};
  return (
    <div className="space-user">
      <Modal
        isOpen={showContent != null}
        onClose={() => setShowContent(null)}
        showClose
      >
        {showContent != null && (
          <>
            <Markdown>{content}</Markdown>
            {/* <Markdown>{atob(showContent?.content)}</Markdown> */}
          </>
        )}
      </Modal>

      <Modal
        isOpen={showCollection != null}
        onClose={() => setShowCollection(null)}
        showClose
      >
        {typeof collection === "string" ? (
          <Markdown>{collection}</Markdown>
        ) : (
          <UserCollection
            data={collection}
            collectionObj={showCollection}
            showTitle={true}
          />
        )}
      </Modal>

      <span
        className="glitch resource-item"
        onClick={(e) => {
          setSelectedUser(spaceUser.user);
          onClearSpaceUsers();
        }}
      >
        {spaceUser?.user?.name.length > 0
          ? spaceUser?.user?.name
          : obfuscateAddress(spaceUser?.user?.address)}
      </span>

      <div style={{ marginLeft: "10px" }}>
        {spaceUser?.contents?.length > 0 && (
          <SpaceContents
            address={address}
            selectedUser={selectedUser}
            contents={spaceUser?.contents}
            onGetUserContent={onGetUserContent}
            onDisplayContent={setShowContent}
            showTitle={showTitle}
          />
        )}
        {spaceUser?.collections?.length > 0 && (
          <SpaceCollection
            address={address}
            collections={spaceUser?.collections}
            collection={collection}
            user={spaceUser?.user}
            onGetUserCollection={onGetUserCollection}
            onDisplayCollection={setShowCollection}
            showTitle={showTitle}
          />
        )}
        {spaceUser?.manifests?.length > 0 && (
          <SpaceFiles
            manifests={spaceUser?.manifests}
            address={address}
            selectedUser={spaceUser?.user}
            formatBytes={formatBytes}
            onReceiveManifest={onReceiveManifest}
            onDeleteManifest={dummyStub}
            showTitle={showTitle}
          />

          // <UserFiles
          //   manifests={spaceUser?.manifests}
          //   address={address}
          //   selectedUser={spaceUser?.user}
          //   formatBytes={formatBytes}
          //   onReceiveManifest={onReceiveManifest}
          //   onDeleteManifest={dummyStub}
          //   showTitle={showTitle}
          // />
        )}
      </div>
    </div>
  );
}
