export default function Social({ onOk }) {
  const agree = async () => {
    if (onOk) onOk(true);
  };

  return (
    <div className="terms">
      <h2>Social</h2>
      <p></p>
      <h3>Networks</h3>
      <p>
        <a href="https://www.linkedin.com/company/datafund/">LinkedIn</a>
      </p>
      <h3>Communication</h3>

      <p>
        <a href="https://twitter.com/datafundproject?lang=en">Twitter</a>
      </p>
      <h3>Community</h3>
      <p>
        <a href="https://web.telegram.org/k/#@DataFund ">Telegram</a>
      </p>

      {/* <button onClick={() => agree()}>Ok</button> */}
    </div>
  );
}
