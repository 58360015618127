const bzzJson = `[
  {
    "file": "filename.ext",
    "reference": "37fcbbd43d229fefeba67af1bc8bde9ab3b096cac283dea24a9d0a9bf1cab552",
    "size": 42,
    "sha256": "cf25cdf4719f181d1d1d371973285d9afe9afde0d0c6a6fd48de857555ce1e0d",
    "contentType": "application/json"
  }
]`;

export default function UserSpacesInfo({ hub, goToUserHome }) {
  const onExploreUsers = async () => {
    await hub.invoke("SearchAllSpacesForUsers", "");
  };
  const onExploreContent = async () => {
    await hub.invoke("SearchAllSpacesForContents", "");
  };
  const onExploreCollections = async () => {
    await hub.invoke("SearchAllSpacesForCollections", "");
  };
  const onExploreFiles = async () => {
    await hub.invoke("SearchAllSpacesForManifests", "");
  };
  const onGetNodeActions = async () => {
    await hub.invoke("Node_GetActions", " ");
  };

  return (
    <div className="user-data">
      <h1>Spaces</h1>
      <div>
        <h4
          className="resource-1-item"
          onClick={() => goToUserHome()}
          style={{ cursor: "pointer" }}
        >
          🏠&nbsp;&nbsp;Home
        </h4>
        Your projects, files in vault users can pay for, shared content and
        Swarm collections.
        <h2>Explore</h2>
        <h4
          onClick={() => onExploreContent()}
          className="resource-1-item"
          style={{ cursor: "pointer" }}
        >
          📖&nbsp;&nbsp;Contents
        </h4>
        User content on this node. Upload <i>.md</i> files for content.
        <h4
          onClick={() => onExploreCollections()}
          className="resource-1-item"
          style={{ cursor: "pointer" }}
        >
          🗄&nbsp;&nbsp;Collections
        </h4>
        User Swarm Collections on this node. Upload <i>.bzz.json</i> for
        collections.
        <h4
          onClick={() => onExploreFiles()}
          className="resource-1-item"
          style={{ cursor: "pointer" }}
        >
          🔐&nbsp;&nbsp;Vaults
        </h4>
        Users pay-to-download files on this node. Upload other files for other
        users to download and get paid through relay service.
        {/* <h3 className="resource-1-item">🔍 Search</h3>
        Search account addresses, names, contents, collections and filenames. */}
        {/* 📂 Files */}
        {/* <br />
        <h4>Collection format</h4>
        <pre>{JSON.stringify(JSON.parse(bzzJson), null, 2)}</pre> */}
        {/* Explore{" "}
        <span className="resource-item" onClick={() => onExploreContent()}>
          <span className="resource-not-important"> 📖 </span>contents
        </span>
        ,{" "}
        <span className="resource-item" onClick={() => onExploreCollections()}>
          <span className="resource-not-important">🗄 </span>collections{" "}
        </span>
        or{" "}
        <span className="resource-item" onClick={() => onExploreFiles()}>
          <span className="resource-not-important">🔐 </span>vaults
        </span>{" "}
        of{" "}
        <span className="resource-item" onClick={() => onExploreUsers()}>
          <span className="resource-not-important">👤 </span>users
        </span>{" "}
        or go{" "} */}
        {/* <hr /> */}
        {/* {" "}
        or available{" "}
        <span className="resource-item" onClick={() => onGetNodeActions()}>
          ◆ actions.
        </span> */}
      </div>
      <br />
    </div>
  );
}
