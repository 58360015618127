import React, { useState, useEffect } from "react";
import staticNodeActions from "./ActionMarketplace.static.js";
import convertToCapitalSpaceCase from "../utils/convertToCapitalSpaceCase.js";
import { Card, CardContent, Badge } from "./ui/Cards.js";

const GroupCard = ({ name }) => (
  <Card className="group-card">
    <CardContent className="group-card__content">
      {/* <h3 className="group-card__name">{name}</h3> */}
      <Badge variant="secondary" className="product-card__badge">
        {convertToCapitalSpaceCase(name)}
      </Badge>
    </CardContent>
  </Card>
);

/*
// node action
{
    "name": "BudgetVarianceReporting",
    "description": "Analyze and report on budget variances, identifying differences between actual and budgeted figures and their causes.",
    "providerNodeId": "33a61761aa154073bf81474d269399b0",
    "resource": "",
    "data": "",
    "owner": "",
    "connectionId": "",
    "fileTypes": ".txt, .md, .csv, .json",
    "s": "",
    "sm": "",
    "project": "",
    "m": null,
    "time": 1729769817,
    "d": 0,
    "g": "BusinessReporting",
    "sc": 4,
    "r": 0,
    "wh": ""
}
*/

const GroupsMarketplace = ({ nodeActions }) => {
  // const products = [
  //   {
  //     image: "/api/placeholder/400/300",
  //     title: "WhisperIt",
  //     publisher: "ThingTank",
  //     g: "VOICE TO TEXT",
  //     description:
  //       "WhisperIt is an AI agent designed to convert audio and video content to text, generating accurate subtitles. Leveraging advanced AI models and cutting-edge technologies, WhisperIt aims to provide high-quality transcription services.",
  //     free: true,
  //     visitors: 86,
  //   },
  //   {
  //     image: "/api/placeholder/400/300",
  //     title: "Investimate",
  //     publisher: "Investimate",
  //     getSelection: "ANALYTICS",
  //     description:
  //       "InvestiMate is a bot designed to assist traders by providing access to a comprehensive stock market knowledge base and real-time market data. It helps users learn essential trading concepts and conduct in-depth research on...",
  //     free: true,
  //     visitors: 125,
  //   },
  //   // Add more product objects here...
  // ];
  // get 10 random actions from staticNodeActions (from ActionMarketplace.static.js)
  const randomActions = [];
  for (let i = 0; i < 5; i++) {
    const randomIndex = Math.floor(Math.random() * staticNodeActions.length);
    randomActions.push(staticNodeActions[randomIndex]);
  }
  // get all distinct groups (.g) from staticNodeActions
  const groups = staticNodeActions.reduce((acc, action) => {
    if (!acc.includes(action.g)) {
      acc.push(action.g);
    }
    return acc;
  }, []);

  return (
    <div style={{ padding: "20px" }}>
      <section style={{ marginTop: "20px" }}>
        <h2>Groups</h2>
        <div className="product-cards">
          {groups.map((group, index) => (
            <GroupCard key={index} name={group} />
          ))}
        </div>
        <h4>{groups.length} Groups</h4>
      </section>
      {/* <section style={{ marginTop: "20px" }}>
        <h3> Tasks {staticNodeActions.length}</h3>
      </section> */}
    </div>
  );
};

export default GroupsMarketplace;
