import React, { useEffect, useState } from "react";
import FileUploader from "./../FileUploader";
import Modal from "./../components/Modal";
import FolderUploader from "../FolderUploader";
import ViewTokenBalances from "./tokens/Tokens";

function UserMenu({
  web3,
  balance,
  tokenBalance,
  assetName,
  address,
  refreshTokenBalance,
  onUploadProfileSettings,
  hub,
  onSearchUsers,
  userData,
  onGetUserData,
  formatBytes,
  onBuyStorage,
  searchDisabled,
  onFolderUploadFinished,
  onDisplaySwarmHashDownload,
}) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isUploadOpen, _setIsUploadOpen] = useState(false);
  const [isBuyStorageOpen, setIsBuyStorageOpen] = useState(false);

  const [username, setUsername] = useState("");
  const [userinfo, setUserinfo] = useState("");
  const [userimage, setUserimage] = useState("");
  const [amount, setAmount] = useState(10);

  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("signature");
    localStorage.removeItem("signMessage");
    window.location.reload();
  };

  function setIsUploadOpen(value) {
    _setIsUploadOpen(value);
    if (value === false) onGetUserData();
  }

  useEffect(() => {
    if (userData) {
      try {
        setUsername(userData?.user?.name);
        setUserinfo(userData?.user?.info);
        setUserimage(userData?.user?.image);
      } catch (e) {
        // console.log("error userData", e);
      }
    }
  }, [userData]);

  useEffect(() => {
    console.log("searchDisabled", searchDisabled);
  }, [searchDisabled]);

  const uploadSettings = () => {
    //console.log("uploadSettings", username, userinfo, userimage);
    onUploadProfileSettings(username, userinfo, userimage, "");
  };
  //console.log("isSearchActive", searchDisabled);
  return (
    <div className="wallet">
      <div className="wallet-header">
        <div>
          {searchDisabled === true && "🔒"}
          <span onClick={() => setIsMenuOpen(!isMenuOpen)}>☰</span>
          {isMenuOpen && (
            <div
              className="wallet-menu"
              onMouseLeave={() => setIsMenuOpen(false)}
            >
              <span
                onClick={() => setIsUploadOpen(true)}
                className="resource-item"
              >
                📁 Manage your space
              </span>
              <br />
              <span
                onClick={() => setIsSettingsOpen(true)}
                className="resource-item"
              >
                ⚙ Public Profile settings
              </span>{" "}
              <br />
              <span
                onClick={() => setIsProfileOpen(true)}
                className="resource-item"
              >
                👤 Profile details
              </span>{" "}
              <br />
              <span
                onClick={() => onDisplaySwarmHashDownload(true)}
                className="resource-item"
              >
                {/* <img
                  src="./swarm_logo_only_b_w.jpeg"
                  alt="user"
                  style={{ width: "20px", height: "20px" }}
                />{" "} */}
                ⬡ Download from Swarm
              </span>
              <br />
              <input
                type="text"
                id="search_users"
                disabled={searchDisabled}
                style={{ height: "1rem" }}
                placeholder="🔍 Search node"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    onSearchUsers(e.target.value);
                  }
                }}
              />
              <ViewTokenBalances walletAddress={address} />
            </div>
          )}
        </div>
      </div>
      <Modal
        isOpen={isProfileOpen}
        onClose={() => setIsProfileOpen(false)}
        showClose
      >
        <div className="wallet-modal">
          <h2>Profile</h2>
          <div>{address}</div>
          <hr />
          <div>
            Balance:
            <strong>{web3.utils.fromWei(balance.toString(), "ether")}</strong>
          </div>
          <div>
            Tokens:
            <strong>
              {tokenBalance.toString()} {assetName}
            </strong>
          </div>

          <strong
            style={{ cursor: "pointer" }}
            onClick={() => refreshTokenBalance()}
          >
            Refresh ⟳
          </strong>
        </div>

        <hr />
        <div>
          Close connection to service and logout <br />
          <button onClick={handleLogout}>Logout</button>
        </div>
        <hr />
      </Modal>
      <Modal
        isOpen={isUploadOpen}
        onClose={() => setIsUploadOpen(false)}
        showClose
      >
        <div className="wallet-modal">
          <h3>Your space</h3>

          <div>
            Space {formatBytes(userData?.user?.storage)}
            {/* Consumed:{" "}
            {formatBytes(userData?.user?.consumed)} */}
            <br />
            <button onClick={() => setIsBuyStorageOpen(true)}>
              Reserve Space
            </button>
          </div>
          <hr />
          <div>
            <h3>Upload files to your space </h3>
            Upload collections <i>(.bzz.json)</i>, markdown <i>(.md)</i>
            or other files <br />
            <FileUploader
              connection={hub}
              //   onUpload={() => setIsOpen(false)}
              //   onUploadComplete={() => setIsOpen(true)}
            />
            <hr />
          </div>
          <div>
            <h3>Upload folders to your space </h3>
            <FolderUploader
              connection={hub}
              user={userData?.user}
              onFolderUploadFinished={onFolderUploadFinished}
              //   onUpload={() => setIsOpen(false)}
              //   onUploadComplete={() => setIsOpen(true)}
            />
          </div>
        </div>
        <hr />
      </Modal>
      <Modal
        isOpen={isBuyStorageOpen}
        onClose={() => setIsBuyStorageOpen(false)}
        showClose
      >
        <div className="wallet-modal">
          <h2>Reserve more space</h2>
          Available: {formatBytes(userData?.user?.storage)} <br />
          Consumed: {formatBytes(userData?.user?.consumed)} <hr />
          <div>
            <h3>Enter amount</h3>
            <input
              type="number"
              placeholder="amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
            MB
            <br />
            <button onClick={() => onBuyStorage(amount)}>
              &nbsp;&nbsp;&nbsp;Reserve&nbsp;&nbsp;&nbsp;
            </button>
          </div>
          <hr />
          This will reserve your space for consumption on the node and is not
          refundable.
        </div>
      </Modal>
      <Modal
        isOpen={isSettingsOpen}
        onClose={() => {
          setIsSettingsOpen(false);
        }}
        showClose
      >
        <div className="wallet-modal">
          <h2>Settings</h2>
          <div>
            User name <br />
            <input
              type="text"
              placeholder=".username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />{" "}
            <br />
            User info <br />
            <input
              type="text"
              placeholder=".userinfo"
              value={userinfo}
              onChange={(e) => setUserinfo(e.target.value)}
            />{" "}
            <br />
            User image <br />
            <input
              type="text"
              placeholder=".userimage"
              value={userimage}
              onChange={(e) => setUserimage(e.target.value)}
            />{" "}
            <br />
            <span>Overwrites all current account settings</span>
          </div>
          <button onClick={() => uploadSettings()}>Save</button>
        </div>

        <hr />
      </Modal>
    </div>
  );
}

export default UserMenu;

// write simple component that has "cog" icon and opens a menu wuth "balance" "address" "refresh" and "Logout" menu items
// use "useModal" hook to open and close the menu

/*
{
    "user": {
        "address": "0xdaa070D909E010211606144eDe5B2ca6864C2c1c",
        "info": "Maca Info",
        "name": "Mica",
        "likes": "",
        "score": 0.04819684,
        "image": "",
        "misc": "",
        "consumed": 28314775,
        "storage": 1048576,
        "actualStorage": 448536,
        "created": 638490567502182000,
        "updated": 638496641705282600,
        "status": "active",
        "nodeId": "19aca7f00b5640869abc44cbdd0d806e",
        "nodeName": null
    },
    "usage": 4496155,
    "outstandingPayments": {
        "8a0b9dd0c87966418ea9a46aa07162786b193d792d8f6857bf22fa1bc66947f4": {
            "price": 0.0000190001,
            "resourcePath": "0xdaa070D909E010211606144eDe5B2ca6864C2c1c/8a0b9dd0c87966418ea9a46aa07162786b193d792d8f6857bf22fa1bc66947f4",
            "owner": "0xd27ffA0e47Fca8D3E757B4d2C408169859B8c419",
            "fileName": "Screenshot 2024-02-22 at 12.43.03.png",
            "dateTime": "04/25/2024 17:49:30",
            "size": 190001
        },
        "da059c56220a6dedee791354fb54e51f6e0246f7a40e670a64304b3bc17cda18": {
            "price": 0.00175121,
            "resourcePath": "0xdaa070D909E010211606144eDe5B2ca6864C2c1c/da059c56220a6dedee791354fb54e51f6e0246f7a40e670a64304b3bc17cda18",
            "owner": "0xd27ffA0e47Fca8D3E757B4d2C408169859B8c419",
            "fileName": "Screenshot 2024-04-11 at 12.44.27.png",
            "dateTime": "04/18/2024 17:25:34",
            "size": 175121
        },
        "e21f308d902272d1485a790ae28809e0f205254beb20d49407162aaf6124d371": {
            "price": 0.01436584,
            "resourcePath": "0xdaa070D909E010211606144eDe5B2ca6864C2c1c/e21f308d902272d1485a790ae28809e0f205254beb20d49407162aaf6124d371",
            "owner": "0xd27ffA0e47Fca8D3E757B4d2C408169859B8c419",
            "fileName": "Screenshot 2024-04-09 at 13.56.02.png",
            "dateTime": "04/19/2024 11:29:31",
            "size": 1436584
        },
        "1c4a68660c4c2b0d105018071a9a0323bdfc77c456daa6b8d32e38974664fed4": {
            "price": 0.02409842,
            "resourcePath": "0xdaa070D909E010211606144eDe5B2ca6864C2c1c/1c4a68660c4c2b0d105018071a9a0323bdfc77c456daa6b8d32e38974664fed4",
            "owner": "0xd27ffA0e47Fca8D3E757B4d2C408169859B8c419",
            "fileName": "Screenshot 2024-03-25 at 16.35.47.png",
            "dateTime": "04/19/2024 11:29:48",
            "size": 2409842
        }
    }
}*/
