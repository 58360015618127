export const Card = ({ children, className }) => (
  <div className={`card ${className}`}>{children}</div>
);
export const CardHeader = ({ children, className }) => (
  <div className={`card-header ${className}`}>{children}</div>
);
export const CardContent = ({ children, className }) => (
  <div className={`card-content ${className}`}>{children}</div>
);
export const CardFooter = ({ children, className }) => (
  <div className={`card-footer ${className}`}>{children}</div>
);
export const Badge = ({ children, variant, className }) => (
  <span className={`badge badge-${variant} ${className}`}>{children}</span>
);
export const CardTitle = ({ children, className }) => (
  <div className={`card-header ${className}`}>{children}</div>
);
