export default function Privacy({ onOk }) {
  const agree = async () => {
    console.log("agree");
    if (onOk) onOk(true);
  };
  return (
    <div className="terms">
      <h2>Privacy</h2>
      Word of advice:
      <h3>Data you upload is public !</h3>
      Don't upload any personal data.
      <p>
        Take care of your data. We don't do:
        <ul>
          <li>no location tracking </li>
          <li>no browsing habits</li>
          <li>no digital footprints, but what you make </li>
          <li>no third-party Tracking</li>
          <li>data minimization</li>
        </ul>
        Digital footprints left by individuals are vast and valuable! We don't
        track you. Keep in mind that AI services will see your data. If you
        don't want to share your data, don't use this service.
        {/* <hr /> */}
        {/* <h3>Data Breaches</h3>
        <p>
          This service had no data breaches and known vulnerabilities until now.
        </p> */}
      </p>
      {/* <button onClick={() => agree()}>Ok</button> */}
    </div>
  );
}
