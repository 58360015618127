export default function About({ onOk }) {
  const agree = async () => {
    console.log("agree");
    if (onOk) onOk(true);
  };

  return (
    <div className="terms">
      <h2>About Spaces</h2>
      <strong>This is beta software, use it at your own risk.</strong>
      <h3>Spaces</h3>A platform for creating and sharing digital content. Handle
      and process data through various AI tasks, AI personas and services.
      <h3>Tasks and Agents</h3>
      Perform AI-driven tasks and actions, character-based task separation and
      analysis. Advanced services for invoking tasks on your data.
      <h3>Pay-to-Download</h3>
      Directly purchase or sell files. Data integrity verification and
      availability is done with the Swarm network.
      <h3>Ethereum and Swarm Integration</h3>
      Interacting with Ethereum and Swarm services for decentralized storage and
      blockchain operations.
      <h3>Data Relay and Processing</h3>
      Real-time communication with nodes services.
    </div>
  );
}
