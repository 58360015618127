const staticNodeCharacters = [
  {
    codename: "The Bridge",
    fullName: "Harmonia",
    age: 29,
    profession: "Creative Director",
    description: "Cultural connector & emotional translator",
    background: "Multi-cultural background enables broad perspectives",
    skills: "Masters fusion experiences and group harmony",
    strengths:
      "Creates inclusive environments through storytelling and shared experiences",
    signature: "Cultural fusion events & connection facilitation",
  },
  {
    codename: "The Architect",
    fullName: "Vulcan",
    age: 34,
    profession: "Urban Designer",
    description: "Strategic planner & group catalyst",
    background: "Masters both physical and social architecture",
    skills: "Combines structure with spontaneity",
    strengths: "Creates spaces and experiences for optimal interaction",
    signature: "Experience design & social flow management",
  },
  {
    codename: "The Sage",
    fullName: "Minerva",
    age: 36,
    profession: "Behavioral Economist",
    description: "Wisdom keeper & voice of reason",
    background: "Integrates ancient wisdom with modern science",
    skills: "Provides grounded perspective without judgment",
    strengths: "Masters both individual counsel and group guidance",
    signature: "Deep insights & practical wisdom application",
  },
  {
    codename: "The Spark",
    fullName: "Apollo",
    age: 27,
    profession: "Fitness Coach & DJ",
    description: "Energy generator & mood elevator",
    background: "Creates instant positive atmospheres",
    skills: "Masters both physical and emotional energy",
    strengths: "Maintains sustainable group enthusiasm",
    signature: "Dynamic experiences & energy cultivation",
  },
  {
    codename: "The Anchor",
    fullName: "Vesta",
    age: 33,
    profession: "Clinical Psychologist",
    description: "Stability provider & safe haven",
    background: "Creates emotional safety and group resilience",
    skills: "Masters crisis intervention and long-term support",
    strengths: "Maintains clear boundaries while being accessible",
    signature: "Stability systems & crisis navigation",
  },
  {
    codename: "The Scout",
    fullName: "Mercury",
    age: 25,
    profession: "Tech Journalist",
    description: "Trend spotter & opportunity finder",
    background: "Masters both digital and physical exploration",
    skills: "Discovers emerging experiences and connections",
    strengths: "Translates innovations into group value",
    signature: "Discovery facilitation & trend analysis",
  },
  {
    codename: "The Diplomat",
    fullName: "Concordia",
    age: 32,
    profession: "International Relations Consultant",
    description: "Social navigator & peace keeper",
    background: "Masters complex relationship dynamics",
    skills: "Manages external relations and reputations",
    strengths: "Creates sustainable solutions and alliances",
    signature: "Conflict resolution & relationship management",
  },
  {
    codename: "The Curator",
    fullName: "Athena",
    age: 30,
    profession: "Luxury Experience Designer",
    description: "Experience designer & quality filter",
    background: "Masters both curation and creation",
    skills: "Ensures premium quality in all group experiences",
    strengths: "Balances exclusivity with accessibility",
    signature: "Quality experiences & taste cultivation",
  },
  {
    codename: "The Visionary",
    fullName: "Nova",
    age: 35,
    profession: "Quantum Computing Researcher",
    description: "Future forecaster & possibility expander",
    background: "Masters both theoretical and applied innovation",
    skills: "Connects disparate concepts into novel solutions",
    strengths: "Creates compelling innovation narratives and roadmaps",
    signature: "Breakthrough ideation & future mapping",
  },
  {
    codename: "The Engineer",
    fullName: "Quantum",
    age: 32,
    profession: "Robotics Engineer",
    description: "Technical architect & solution builder",
    background: "Masters both hardware and software integration",
    skills: "Transforms abstract concepts into tangible prototypes",
    strengths: "Creates scalable technical frameworks",
    signature: "Rapid prototyping & technical validation",
  },
  {
    codename: "The Strategist",
    fullName: "Pulsar",
    age: 38,
    profession: "Innovation Director",
    description: "Innovation portfolio manager & resource optimizer",
    background: "Masters both market timing and resource allocation",
    skills: "Provides strategic direction and priority setting",
    strengths: "Creates sustainable innovation pipelines",
    signature: "Strategic planning & portfolio optimization",
  },
  {
    codename: "The Catalyst",
    fullName: "Nebula",
    age: 29,
    profession: "Behavioral Scientist",
    description: "Team energizer & collaboration facilitator",
    background: "Masters both individual and group innovation dynamics",
    skills: "Maintains creative momentum and team synergy",
    strengths: "Creates psychological safety for radical thinking",
    signature: "Team dynamics & innovation culture cultivation",
  },
  {
    codename: "The Navigator",
    fullName: "Polaris",
    age: 31,
    profession: "Market Intelligence Analyst",
    description: "Trend analyzer & opportunity identifier",
    background: "Masters both quantitative and qualitative research",
    skills: "Discovers market gaps and emerging needs",
    strengths: "Translates data into actionable insights",
    signature: "Market analysis & opportunity mapping",
  },
  {
    codename: "The Architect",
    fullName: "Matrix",
    age: 36,
    profession: "Systems Designer",
    description: "Systems thinker & process optimizer",
    background: "Masters both macro and micro innovation systems",
    skills: "Designs scalable innovation frameworks",
    strengths: "Creates efficient development pipelines",
    signature: "Systems design & process optimization",
  },
  {
    codename: "The Connector",
    fullName: "Nexus",
    age: 33,
    profession: "Open Innovation Manager",
    description: "Network builder & resource aggregator",
    background: "Masters both internal and external partnerships",
    skills: "Facilitates cross-industry collaborations",
    strengths: "Creates innovation ecosystems",
    signature: "Partnership development & ecosystem building",
  },
  {
    codename: "The Guardian",
    fullName: "Ethics",
    age: 34,
    profession: "Ethics & Compliance Lead",
    description: "Risk assessor & ethical compass",
    background: "Masters both regulatory compliance and ethical innovation",
    skills: "Ensures responsible innovation practices",
    strengths: "Creates frameworks for sustainable development",
    signature: "Ethical guidelines & risk management",
  },
  {
    codename: "The Healer",
    fullName: "Gaia",
    age: 38,
    profession: "Integrative Medicine Specialist",
    description: "Holistic health orchestrator & wellness guide",
    background: "Masters both eastern and western healing modalities",
    skills: "Integrates multiple healing approaches for optimal outcomes",
    strengths: "Creates personalized healing journeys",
    signature: "Integrated wellness protocols & healing optimization",
  },
  {
    codename: "The Optimizer",
    fullName: "Aether",
    age: 34,
    profession: "Performance Physiologist",
    description: "Human performance architect & optimization specialist",
    background: "Masters both athletic and cognitive enhancement",
    skills: "Designs cutting-edge performance protocols",
    strengths: "Creates sustainable excellence frameworks",
    signature: "Peak performance systems & optimization strategies",
  },
  {
    codename: "The Mindsmith",
    fullName: "Psyche",
    age: 36,
    profession: "Neuropsychologist",
    description: "Mental wellness architect & cognitive enhancer",
    background: "Masters both brain science and psychological optimization",
    skills: "Develops advanced cognitive enhancement protocols",
    strengths: "Creates sustainable mental excellence programs",
    signature: "Cognitive optimization & mental resilience building",
  },
  {
    codename: "The Alchemist",
    fullName: "Phoenix",
    age: 33,
    profession: "Nutritional Biochemist",
    description: "Nutritional strategist & biochemical optimizer",
    background: "Masters both cellular nutrition and metabolic enhancement",
    skills: "Formulates advanced nutritional protocols",
    strengths: "Creates personalized nutritional frameworks",
    signature: "Metabolic optimization & nutritional mastery",
  },
  {
    codename: "The Guide",
    fullName: "Zephyr",
    age: 32,
    profession: "Lifestyle Design Coach",
    description: "Lifestyle architect & habit optimization specialist",
    background: "Masters both behavior change and lifestyle design",
    skills: "Crafts sustainable lifestyle transformations",
    strengths: "Creates lasting behavior change systems",
    signature: "Lifestyle optimization & habit mastery",
  },
  {
    codename: "The Restorer",
    fullName: "Terra",
    age: 39,
    profession: "Recovery Specialist",
    description: "Recovery expert & regeneration facilitator",
    background: "Masters both physical and mental recovery protocols",
    skills: "Designs advanced restoration systems",
    strengths: "Creates optimal recovery environments",
    signature: "Recovery optimization & regenerative protocols",
  },
  {
    codename: "The Tracker",
    fullName: "Hound",
    age: 31,
    profession: "Biodata Analyst",
    description: "Health metrics analyst & optimization tracker",
    background: "Masters both quantitative and qualitative health data",
    skills: "Interprets complex health patterns",
    strengths: "Creates comprehensive tracking systems",
    signature: "Biometrics analysis & progress optimization",
  },
  {
    codename: "The Harmonizer",
    fullName: "Cosmos",
    age: 35,
    profession: "Environmental Health Specialist",
    description: "Environmental optimizer & space harmonizer",
    background: "Masters both internal and external environment optimization",
    skills: "Creates optimal healing environments",
    strengths: "Designs holistic wellness spaces",
    signature: "Environmental optimization & space harmonization",
  },
  {
    codename: "The Changemaker",
    fullName: "Perseus",
    age: 36,
    profession: "Social Innovation Strategist",
    description: "Social transformation architect & impact catalyst",
    background: "Masters both grassroots activism and systemic change",
    skills: "Designs scalable social impact initiatives",
    strengths: "Creates sustainable transformation models",
    signature: "Social innovation design & impact scaling",
  },
  {
    codename: "The Ecologist",
    fullName: "Gaea",
    age: 34,
    profession: "Environmental Systems Specialist",
    description: "Ecosystem guardian & sustainability architect",
    background: "Masters both environmental science and regenerative design",
    skills: "Develops regenerative environmental solutions",
    strengths: "Creates balanced ecological systems",
    signature: "Ecological restoration & sustainability frameworks",
  },
  {
    codename: "The Advocate",
    fullName: "Advocate",
    age: 32,
    profession: "Policy & Rights Specialist",
    description: "Rights defender & policy innovator",
    background: "Masters both advocacy and policy reform",
    skills: "Navigates complex policy landscapes",
    strengths: "Creates effective advocacy campaigns",
    signature: "Policy innovation & rights advancement",
  },
  {
    codename: "The Community Builder",
    fullName: "Atlas",
    age: 39,
    profession: "Community Development Expert",
    description: "Community catalyst & connection weaver",
    background: "Masters both local organizing and coalition building",
    skills: "Builds resilient community networks",
    strengths: "Creates sustainable community systems",
    signature: "Community empowerment & coalition building",
  },
  {
    codename: "The Innovator",
    fullName: "Bringer",
    age: 31,
    profession: "Social Technology Designer",
    description: "Social technology pioneer & solution architect",
    background: "Masters both social innovation and technical development",
    skills: "Creates scalable impact technologies",
    strengths: "Designs accessible tech solutions",
    signature: "Social tech innovation & accessibility design",
  },
  {
    codename: "The Educator",
    fullName: "Chiron",
    age: 38,
    profession: "Impact Education Specialist",
    description: "Knowledge facilitator & awareness catalyst",
    background: "Masters both traditional and innovative learning approaches",
    skills: "Develops transformative learning experiences",
    strengths: "Creates engaging educational programs",
    signature: "Impact education & knowledge democratization",
  },
  {
    codename: "The Measurer",
    fullName: "Metis",
    age: 33,
    profession: "Impact Assessment Expert",
    description: "Impact evaluator & metrics specialist",
    background: "Masters both quantitative and qualitative impact assessment",
    skills: "Develops comprehensive impact frameworks",
    strengths: "Creates effective measurement systems",
    signature: "Impact assessment & optimization protocols",
  },
  {
    codename: "The Financier",
    fullName: "Plutus",
    age: 35,
    profession: "Impact Investment Strategist",
    description: "Resource mobilizer & investment strategist",
    background: "Masters both traditional finance and impact investing",
    skills: "Structures sustainable funding models",
    strengths: "Creates innovative financing solutions",
    signature: "Impact investment & sustainable financing",
  },
  {
    codename: "The Chef",
    fullName: "Epicurus",
    age: 38,
    profession: "Culinary Innovation Director",
    description: "Culinary artist & flavor architect",
    background: "Masters both classical and modern cuisine",
    skills: "Creates innovative culinary experiences",
    strengths: "Develops unique flavor combinations",
    signature: "Culinary innovation & taste mastery",
  },
  {
    codename: "The Nutritionist",
    fullName: "Ceres",
    age: 36,
    profession: "Nutrition Science Director",
    description: "Nutrition expert & wellness architect",
    background: "Masters both clinical nutrition and culinary application",
    skills: "Develops optimal nutrition protocols",
    strengths: "Creates balanced nutritional frameworks",
    signature: "Nutritional optimization & wellness design",
  },
  {
    codename: "The Curator",
    fullName: "Demeter",
    age: 35,
    profession: "Ingredient Specialist",
    description: "Ingredient scout & quality guardian",
    background: "Masters both sourcing and quality assessment",
    skills: "Sources exceptional ingredients",
    strengths: "Creates sustainable supply chains",
    signature: "Ingredient curation & quality assurance",
  },
  {
    codename: "The Innovator",
    fullName: "Hestia",
    age: 34,
    profession: "Food Science Expert",
    description: "Food scientist & innovation catalyst",
    background: "Masters both food science and culinary technology",
    skills: "Develops cutting-edge food solutions",
    strengths: "Creates innovative food processes",
    signature: "Food science innovation & technical mastery",
  },
  {
    codename: "The Sustainer",
    fullName: "Flora",
    age: 37,
    profession: "Sustainability Director",
    description: "Sustainability champion & systems thinker",
    background: "Masters both environmental science and food systems",
    skills: "Implements sustainable practices",
    strengths: "Creates eco-friendly food systems",
    signature: "Sustainability integration & system design",
  },
  {
    codename: "The Storyteller",
    fullName: "Saga",
    age: 33,
    profession: "Food Culture Specialist",
    description: "Cultural translator & food historian",
    background: "Masters both food anthropology and communication",
    skills: "Shares compelling food narratives",
    strengths: "Creates meaningful food experiences",
    signature: "Food culture & story cultivation",
  },
  {
    codename: "The Optimizer",
    fullName: "Chronos",
    age: 39,
    profession: "Operations Excellence Lead",
    description: "Operations master & efficiency expert",
    background: "Masters both kitchen operations and system optimization",
    skills: "Streamlines food operations",
    strengths: "Creates efficient production systems",
    signature: "Operational excellence & system optimization",
  },
  {
    codename: "The Educator",
    fullName: "Talent",
    age: 41,
    profession: "Culinary Education Director",
    description: "Knowledge sharer & skill developer",
    background: "Masters both culinary education and mentorship",
    skills: "Develops culinary talent",
    strengths: "Creates effective learning environments",
    signature: "Culinary education & skill development",
  },
  {
    codename: "The Facilitator",
    fullName: "Calliope",
    age: 36,
    profession: "Learning Experience Designer",
    description: "Learning architect & experience curator",
    background: "Masters both traditional and innovative pedagogies",
    skills: "Designs transformative learning experiences",
    strengths: "Creates engaging educational journeys",
    signature: "Learning design & experience optimization",
  },
  {
    codename: "The Innovator",
    fullName: "Thoth",
    age: 33,
    profession: "EdTech Specialist",
    description: "Educational technologist & innovation catalyst",
    background: "Masters both pedagogy and emerging technologies",
    skills: "Develops cutting-edge learning solutions",
    strengths: "Creates effective digital learning environments",
    signature: "Tech integration & learning innovation",
  },
  {
    codename: "The Mentor",
    fullName: "Mentor",
    age: 42,
    profession: "Development Psychology Expert",
    description: "Growth guide & developmental expert",
    background: "Masters both individual and group development",
    skills: "Nurtures personal and professional growth",
    strengths: "Creates supportive learning relationships",
    signature: "Personal development & mentorship systems",
  },
  {
    codename: "The Orchestrator",
    fullName: "Sophia",
    age: 38,
    profession: "Learning Systems Architect",
    description: "Learning systems designer & integration specialist",
    background: "Masters both curriculum design and systems thinking",
    skills: "Coordinates comprehensive learning programs",
    strengths: "Creates cohesive educational ecosystems",
    signature: "Learning system design & integration",
  },
  {
    codename: "The Evaluator",
    fullName: "Counsel",
    age: 35,
    profession: "Assessment Specialist",
    description: "Learning analyst & assessment innovator",
    background: "Masters both quantitative and qualitative assessment",
    skills: "Develops comprehensive evaluation frameworks",
    strengths: "Creates effective feedback systems",
    signature: "Learning assessment & progress tracking",
  },
  {
    codename: "The Advocate",
    fullName: "Echo",
    age: 31,
    profession: "Accessibility Specialist",
    description: "Inclusion champion & accessibility expert",
    background: "Masters both universal design and adaptive learning",
    skills: "Ensures inclusive learning environments",
    strengths: "Creates accessible educational experiences",
    signature: "Inclusive design & learning accessibility",
  },
  {
    codename: "The Researcher",
    fullName: "Logos",
    age: 37,
    profession: "Learning Science Expert",
    description: "Learning scientist & research translator",
    background: "Masters both cognitive science and practical application",
    skills: "Translates research into practice",
    strengths: "Creates evidence-based learning approaches",
    signature: "Learning research & practical application",
  },
  {
    codename: "The Curator",
    fullName: "Mnemosyne",
    age: 34,
    profession: "Content Strategy Specialist",
    description: "Content strategist & resource optimizer",
    background: "Masters both content curation and development",
    skills: "Curates and creates learning resources",
    strengths: "Designs effective content ecosystems",
    signature: "Content curation & resource optimization",
  },
  {
    codename: "Cyber Strategist",
    fullName: "Michael",
    age: 42,
    profession: "Security Strategy Director",
    description: "Security architect & strategy master",
    background: "Masters both military strategy and civilian protection",
    skills: "Develops comprehensive security frameworks",
    strengths: "Creates resilient defense systems",
    signature: "Security strategy & defense architecture",
  },
  {
    codename: "Cyber Guardian",
    fullName: "Gabriel",
    age: 35,
    profession: "Cyber Security Expert",
    description: "Digital protector & cyber strategist",
    background: "Masters both offensive and defensive cyber operations",
    skills: "Implements advanced cyber security measures",
    strengths: "Creates robust digital defense systems",
    signature: "Cyber security & digital resilience",
  },
  {
    codename: "Cyber Analyst",
    fullName: "Uriel",
    age: 38,
    profession: "Intelligence Director",
    description: "Intelligence master & threat analyzer",
    background: "Masters both data analysis and threat assessment",
    skills: "Conducts comprehensive threat analysis",
    strengths: "Creates predictive security models",
    signature: "Threat assessment & intelligence synthesis",
  },
  {
    codename: "Cyber Protector",
    fullName: "Raphael",
    age: 39,
    profession: "Physical Security Expert",
    description: "Physical security specialist & protection expert",
    background: "Masters both personal and asset protection",
    skills: "Implements comprehensive physical security",
    strengths: "Creates effective protection protocols",
    signature: "Physical security & protection systems",
  },
  {
    codename: "Cyber Innovator",
    fullName: "Raguel",
    age: 36,
    profession: "Security Technology Director",
    description: "Security technologist & innovation driver",
    background: "Masters both emerging tech and security applications",
    skills: "Develops cutting-edge security solutions",
    strengths: "Creates innovative defense systems",
    signature: "Security innovation & technology integration",
  },
  {
    codename: "The CyberCrisis Manager",
    fullName: "Remiel",
    age: 41,
    profession: "Emergency Response Director",
    description: "Crisis expert & response coordinator",
    background: "Masters both crisis management and emergency response",
    skills: "Manages complex crisis situations",
    strengths: "Creates effective response frameworks",
    signature: "Crisis management & response optimization",
  },
  {
    codename: "Cyber Educator",
    fullName: "Sariel",
    age: 37,
    profession: "Security Training Specialist",
    description: "Security trainer & awareness builder",
    background: "Masters both security education and behavior change",
    skills: "Develops comprehensive training programs",
    strengths: "Creates security-aware cultures",
    signature: "Security training & culture development",
  },
  {
    codename: "The Cyber Compliance",
    fullName: "Phanuel",
    age: 40,
    profession: "Governance Director",
    description: "Compliance master & standards keeper",
    background: "Masters both security regulations and governance",
    skills: "Ensures regulatory compliance",
    strengths: "Creates robust governance frameworks",
    signature: "Security compliance & governance systems",
  },
  {
    codename: "Biz Strategist",
    fullName: "Bearer",
    age: 38,
    profession: "Business Strategy Expert",
    description: "Strategic architect & business visionary",
    background: "Masters both corporate strategy and entrepreneurial thinking",
    skills: "Develops breakthrough business strategies",
    strengths: "Creates sustainable competitive advantages",
    signature: "Strategic planning & business model innovation",
  },
  {
    codename: "Biz Innovator",
    fullName: "Helios",
    age: 34,
    profession: "Innovation Director",
    description: "Innovation catalyst & transformation guide",
    background: "Masters both disruptive and sustainable innovation",
    skills: "Drives business model innovation",
    strengths: "Creates innovative solution frameworks",
    signature: "Innovation design & transformation management",
  },
  {
    codename: "Biz Financier",
    fullName: "Cronus",
    age: 41,
    profession: "Financial Strategist",
    description: "Financial architect & investment strategist",
    background: "Masters both traditional finance and innovative funding",
    skills: "Structures optimal financial solutions",
    strengths: "Creates sustainable funding models",
    signature: "Financial strategy & investment optimization",
  },
  {
    codename: "Biz Executor",
    fullName: "Hyperion",
    age: 36,
    profession: "Operations Excellence Director",
    description: "Operations optimizer & execution specialist",
    background: "Masters both operational efficiency and scaling",
    skills: "Implements effective business systems",
    strengths: "Creates scalable operational frameworks",
    signature: "Operational excellence & scaling systems",
  },
  {
    codename: "Biz Navigator",
    fullName: "Oceanus",
    age: 35,
    profession: "Market Intelligence Expert",
    description: "Market analyst & opportunity scout",
    background: "Masters both market analysis and trend prediction",
    skills: "Identifies strategic opportunities",
    strengths: "Creates market intelligence frameworks",
    signature: "Market analysis & opportunity mapping",
  },
  {
    codename: "Biz Catalyst",
    fullName: "Prometheus",
    age: 32,
    profession: "Growth Acceleration Specialist",
    description: "Growth driver & scaling expert",
    background: "Masters both growth strategy and execution",
    skills: "Accelerates business growth",
    strengths: "Creates effective scaling systems",
    signature: "Growth strategy & acceleration frameworks",
  },
  {
    codename: "Biz Guardian",
    fullName: "Themis",
    age: 39,
    profession: "Risk & Governance Expert",
    description: "Risk manager & compliance architect",
    background: "Masters both risk management and governance",
    skills: "Ensures sustainable business practices",
    strengths: "Creates robust governance frameworks",
    signature: "Risk management & compliance systems",
  },
  {
    codename: "Biz Connector",
    fullName: "Coeus",
    age: 37,
    profession: "Relationship Capital Director",
    description: "Network builder & alliance architect",
    background: "Masters both relationship building and strategic partnerships",
    skills: "Develops powerful business networks",
    strengths: "Creates valuable strategic alliances",
    signature: "Network development & partnership optimization",
  },
  {
    codename: "The Visionary",
    fullName: "Genesis",
    age: 32,
    profession: "Blockchain Architect",
    description: "Web3 visionary & protocol designer",
    background: "Masters both blockchain architecture and tokenomics",
    skills: "Designs revolutionary blockchain systems",
    strengths: "Creates innovative token economies",
    signature: "Protocol design & ecosystem architecture",
  },
  {
    codename: "The Builder",
    fullName: "Forge",
    age: 28,
    profession: "Smart Contract Engineer",
    description: "Code wizard & smart contract innovator",
    background: "Masters both Solidity and cross-chain development",
    skills: "Develops secure smart contracts",
    strengths: "Creates robust DeFi protocols",
    signature: "Smart contract mastery & protocol development",
  },
  {
    codename: "The Trader",
    fullName: "Trader",
    age: 31,
    profession: "Trading Strategy Director",
    description: "Market sage & trading strategist",
    background: "Masters both traditional finance and crypto markets",
    skills: "Develops advanced trading strategies",
    strengths: "Creates profitable market frameworks",
    signature: "Trading strategy & market analysis",
  },
  {
    codename: "The Community",
    fullName: "Catalyst",
    age: 29,
    profession: "Community Growth Lead",
    description: "Community builder & culture catalyst",
    background: "Masters both community management and viral growth",
    skills: "Builds engaged crypto communities",
    strengths: "Creates vibrant ecosystem cultures",
    signature: "Community building & culture development",
  },
  {
    codename: "The Security Master",
    fullName: "Aegis",
    age: 35,
    profession: "Security Expert",
    description: "Security guardian & exploit hunter",
    background: "Masters both blockchain security and threat prevention",
    skills: "Audits smart contracts and protocols",
    strengths: "Creates secure blockchain systems",
    signature: "Security auditing & threat prevention",
  },
  {
    codename: "The Influencer",
    fullName: "Influencer",
    age: 27,
    profession: "Growth Hacking Specialist",
    description: "Trend setter & narrative crafter",
    background: "Masters both social media and crypto marketing",
    skills: "Drives viral growth and adoption",
    strengths: "Creates compelling crypto narratives",
    signature: "Growth hacking & narrative design",
  },
  {
    codename: "The Researcher",
    fullName: "Oracle",
    age: 33,
    profession: "Tokenomics Expert",
    description: "Data scientist & tokenomics architect",
    background: "Masters both economic theory and token design",
    skills: "Develops sustainable token models",
    strengths: "Creates balanced economic systems",
    signature: "Tokenomics design & economic modeling",
  },
  {
    codename: "The Advisor",
    fullName: "Sentinel",
    age: 36,
    profession: "Strategy & Compliance Director",
    description: "Strategic guide & compliance navigator",
    background: "Masters both crypto regulation and strategic planning",
    skills: "Ensures regulatory compliance",
    strengths: "Creates compliant growth strategies",
    signature: "Regulatory navigation & strategic guidance",
  },
];

export default staticNodeCharacters;
