import React, { useRef, useState } from "react";
import CharacterCard from "./CharacterCard";
const character = {
  codename: "The Bridge",
  fullName: "Harmonia",
  age: 29,
  profession: "Creative Director",
  description: "Cultural connector & emotional translator",
  background: "Multi-cultural background enables broad perspectives",
  skills: "Masters fusion experiences and group harmony",
  strengths:
    "Creates inclusive environments through storytelling and shared experiences",
  signature: "Cultural fusion events & connection facilitation",
};

const CharacterPanelSmall = ({ character }) => {
  return (
    <div className="character-panel">
      <div className="character-profile">
        <h3>{character.profession}</h3>
        <p>{character.fullName}</p>
        <p>{character.codename}</p>
        <p>{character.description}</p>
        <p>{character.skills}</p>
      </div>
      <div className="character-details">
        {/* <div className="detail-item">
          <p>
            {character.background}

            {character.skills}

            {character.strengths}

            {character.signature}
          </p>
        </div> */}
      </div>
    </div>
  );
};

const CharacterPanel = ({ character, onSelectCharacter }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className={`character-panel ${isHovered ? "hovered" : ""}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => onSelectCharacter(character)}
    >
      {isHovered ? (
        <>
          <div className="character-profile">
            <h3>{character.profession}</h3>
            {/* <p>{character.fullName}</p>
            <p>{character.codename}</p> */}
            <p>{character.description}</p>
            <p>{character.skills}</p>
          </div>
          <div className="character-details">
            <div className="detail-item">
              <p>{character.background}</p>
              <p>{character.skills} </p>
              <p>{character.strengths} </p>
              <p>{character.signature} </p>
              <br />
              <br />
            </div>
          </div>
        </>
      ) : (
        <div className="character-profile">
          <h3>{character.profession}</h3>
          {/* <p>{character.fullName}</p>
          <p>{character.codename}</p>
          <p>{character.description}</p>
          <p>{character.skills}</p> */}
        </div>
      )}
    </div>
  );
};

// export default CharacterPanel;

const BottomScrollingPaneAnimated = ({ characters }) => {
  return (
    <div className="bottom-scrolling-panel">
      <div className="scrolling-content">
        {characters.map((character, index) => (
          <CharacterCard key={index} {...character} />
        ))}
        {/* Duplicate the content for seamless scrolling */}
        {/* {characters.map((character, index) => (
          <CharacterCard key={`duplicate-${index}`} {...character} />
        ))} */}
      </div>
    </div>
  );
};
// BottomScrollingPanel.js

// import React, { useRef, useState } from 'react';
// import './BottomScrollingPanel.css';
// import CharacterPanel from './CharacterPanel'; // Ensure this component is created

const BottomScrollingPanel = ({ characters, onSelectCharacter }) => {
  const scrollingRef = useRef(null);
  const isDragging = useRef(false);
  const startX = useRef(0);
  const scrollLeft = useRef(0);

  const handleMouseDown = (e) => {
    isDragging.current = true;
    startX.current = e.pageX - scrollingRef.current.offsetLeft;
    scrollLeft.current = scrollingRef.current.scrollLeft;

    // Add window-level event listeners
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);
  };

  const handleMouseMove = (e) => {
    if (!isDragging.current) return;
    e.preventDefault();

    const x = e.pageX - scrollingRef.current.offsetLeft;
    const walk = (x - startX.current) * 2;
    scrollingRef.current.scrollLeft = scrollLeft.current - walk;
  };

  const handleMouseUp = () => {
    isDragging.current = false;

    // Remove window-level event listeners
    window.removeEventListener("mousemove", handleMouseMove);
    window.removeEventListener("mouseup", handleMouseUp);
  };

  return (
    <div className="bottom-scrolling-panel">
      <div
        className={`scrolling-content ${isDragging.current ? "active" : ""}`}
        ref={scrollingRef}
        onMouseDown={handleMouseDown}
      >
        {characters.map((character, index) => (
          <CharacterPanel
            key={index}
            character={character}
            onSelectCharacter={onSelectCharacter}
          />
        ))}
        {/* {characters.map((character, index) => (
          <CharacterPanel key={`duplicate-${index}`} character={character} />
        ))} */}
      </div>
    </div>
  );
};

export default BottomScrollingPanel;
