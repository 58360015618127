export default function convertToCapitalSpaceCase(str) {
  if (!str) return "";
  // some words never have space, ID, IT RnD, NER, CSRD, ESG, NLP, ML, AI, DL, RL, DRL, GAN, RNN, LSTM, GRU, CNN, DNN, BERT, GPT, T5, XLNet, RoBERTa, ALBERT, DistilBERT, ELECTRA, BART, MarianMT, T5, XLM, XLM-R
  var excluded = [
    "ID",
    "IT",
    "RnD",
    "NER",
    "CSRD",
    "ESG",
    "NLP",
    "ML",
    "AI",
    "DL",
    "RL",
    "DRL",
    "GAN",
    "RNN",
    "LSTM",
    "GRU",
    "CNN",
    "DNN",
    "BERT",
    "GPT",
    "T5",
    "XLNet",
    "RoBERTa",
    "ALBERT",
    "DistilBERT",
    "ELECTRA",
    "BART",
    "MarianMT",
    "T5",
    "XLM",
    "XLM-R",
    "PCA",
    "NPS",
    "OCR",
    "SQL",
    "POS",
    "SWOT",
    "PESTLE",
    "QA",
    "KPI",
    "MACD",
    "PPE",
    "OHS",
    "ROI",
    "RPA",
    "SME",
    "HR",
    "DEI",
    "CSR",
    "CFO",
    "IP",
    "RnD",
    "EHS",
    "ATR",
    "ATP",
    "ATC",
    "ADX",
    "SAR",
    "GDP",
  ];

  //return str.replace(/(?<!^)([A-Z])/g, " $1");
  // console.log("convertToCapitalSpaceCase", str);
  return str
    .split(/(?<!(^|[A-Z]))(?=[A-Z])|(?<!^)(?=[A-Z][a-z])/g)
    .map((word) =>
      !word || excluded.includes(word) ? word : word.replace(/([A-Z])/g, " $1")
    )
    .join(" ");
}
