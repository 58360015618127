// Modal.js
import React from "react";
import "./Modal.css";

function Modal({ isOpen, onClose, showClose, children }) {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        {children}

        {showClose && (
          <>
            <br />
            <button onClick={onClose} style={{ opacity: "0.7" }}>
              Close
            </button>
          </>
        )}
      </div>
    </div>
  );
}

export default Modal;
