import staticNodeCharacters from "./CharactersMarketplace.static.js";
import CharacterCard from "./ui/CharacterCard.js";

const CharactersMarketplace = ({ nodeCharacters }) => {
  const characters = staticNodeCharacters;
  const randomCharacters = [];
  for (let i = 0; i < 5; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomCharacters.push(characters[randomIndex]);
  }

  return (
    <div style={{ padding: "20px" }}>
      {/* Existing header and main content... */}

      <section style={{ marginTop: "20px" }}>
        <h2>Featured AI Personas</h2>
        {/* <small>These are fictional names</small> */}
        <div className="product-cards">
          {randomCharacters.map((character, index) => (
            <CharacterCard key={index} {...character} />
          ))}
        </div>
        <h4>{characters.length} Personas</h4>
      </section>
    </div>
  );
};

export default CharactersMarketplace;
