export default function Terms({ onIsAgreed, token, price }) {
  const agree = async () => {
    console.log("agree");
    if (onIsAgreed) onIsAgreed(true);
    localStorage.setItem("agreed_to_terms", "1");
  };
  return (
    <div className="terms">
      <h2>Terms and Conditions</h2>
      <p>
        This is a beta release of our services and software. By utilizing this
        service, you hereby agree to adhere to the following terms and
        conditions:
      </p>
      <ul>
        <li>
          The fee for downloading shall be {price} {token} per megabyte (MB) of
          data relayed through this service. A service charge of ten percent
          (10%) will be applied.
        </li>
        <li>The maximum permissible file size is ten megabytes (10 MB).</li>
        <li>
          The maximum allowable text size for analysis is eight kilobytes (8
          KB).
        </li>
        <li>
          Each download stream requires a single payment. Refunds are
          non-refundable.
        </li>
        <li>
          Once a streaming session commences, refraining from refreshing or
          closing the dialog is mandatory, as such actions will result in the
          termination of your streaming session.
        </li>
        <li>
          Files sourced from the Swarm network are cached prior to streaming.
        </li>
        <li>
          File persistence is limited, and files may be removed at any time
          without notice.
        </li>
        <li>
          Cached versions may remain on the service for an extended period.
        </li>
        <li>Data may be cached from the Swarm network.</li>
        <li>Mobile wallets are not supported.</li>
        <li>
          If the download process is interrupted, a new payment will be required
          to resume the download.
        </li>
        <li>The files you download may be accessible on the Swarm Network.</li>
        <li>
          No registration or signup is required; your account is linked to your
          wallet.
        </li>
        <li>
          Uploaded files exist within the service (.bzz.json, .md) and may be
          uploaded to the Swarm network.
        </li>
        <li>
          You are entitled to one megabyte (1 MB) of free storage. Subsequent
          uploads will incur charges.
        </li>
      </ul>
      <button onClick={() => agree()}>Agree</button>
    </div>
  );
}
