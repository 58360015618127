// outstandingPayments
// [
// 3684fe3934552758f0c052c8a186aad9faf9eb36954c7383c7d51c28777332be :{
//     "price": 0.00257604,
//     "resourcePath": "0xdaa070D909E010211606144eDe5B2ca6864C2c1c/3684fe3934552758f0c052c8a186aad9faf9eb36954c7383c7d51c28777332be",
//     "owner": "0xd27ffA0e47Fca8D3E757B4d2C408169859B8c419",
//     "filename" : "file1",
// }
// ]

import React, { useEffect } from "react";

export default function OutstandingPayments({
  payments,
  onOutstandingPayment,
}) {
  const [isCollapsed, setIsCollapsed] = React.useState(true);
  useEffect(() => {}, []);

  if (payments === null || payments === undefined) return null;

  if (Object.keys(payments).length === 0) {
    return <div>No outstanding payments</div>;
  }

  return (
    <div>
      <h3>Outstanding Payments</h3>
      <button onClick={() => setIsCollapsed(!isCollapsed)}>
        {isCollapsed ? "Show" : "Hide"}
      </button>
      {!isCollapsed && (
        <ul>
          {Object.entries(payments).map(
            ([paymentId, paymentDetails], index) => {
              const [address, resourceId] =
                paymentDetails.resourcePath.split("/");
              return (
                <li
                  key={index}
                  onClick={() =>
                    onOutstandingPayment(paymentId, paymentDetails)
                  }
                >
                  <span>Payment ID: {paymentId}</span>
                  <br />
                  <span>Price: {paymentDetails.price}</span>
                  <br />
                  {/* <span>Resource Path: {paymentDetails.resourcePath}</span>
              <br /> */}
                  <span>Resource Owner: {address}</span>
                  <br />
                  <span>Resource ID: {resourceId}</span>
                  <br />
                  <span>Beneficiary: {paymentDetails.owner}</span>
                  <br />
                  <span>Filename: {paymentDetails.fileName}</span>
                </li>
              );
            }
          )}
        </ul>
      )}
    </div>
  );
}
