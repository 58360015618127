import React, { useState, useEffect } from "react";

function SmartImageCard({ g, title }) {
  const [imgSrc, setImgSrc] = useState("");
  // const [imgSrc, setImgSrc] = useState(`${g}.webp`);

  useEffect(() => {
    const initialImgSrc = `${g}.webp`;
    //console.log("Initial image source:", initialImgSrc);
    setImgSrc(initialImgSrc);
  }, [g]);

  const handleError = () => {
    setImgSrc("marketplace-logo.webp");
  };

  return (
    <img
      src={imgSrc}
      alt={title}
      className="product-card__image"
      onError={handleError}
    />
  );
}

export default SmartImageCard;
